"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/index.ts
var src_exports = {};
__export(src_exports, {
  ABI: () => ABI,
  ADDRESS: () => ADDRESS,
  BLOOM: () => BLOOM,
  CERTIFICATE: () => CERTIFICATE,
  CONTRACT: () => CONTRACT,
  CertificateInvalidSignatureFormatError: () => CertificateInvalidSignatureFormatError,
  CertificateInvalidSignerError: () => CertificateInvalidSignerError,
  CertificateNotSignedError: () => CertificateNotSignedError,
  ContractDeploymentFailedError: () => ContractDeploymentFailedError,
  ContractInterfaceError: () => ContractInterfaceError,
  DATA: () => DATA,
  EIP1193: () => EIP1193,
  EIP1193ChainDisconnected: () => EIP1193ChainDisconnected,
  EIP1193Disconnected: () => EIP1193Disconnected,
  EIP1193Unauthorized: () => EIP1193Unauthorized,
  EIP1193UnsupportedMethod: () => EIP1193UnsupportedMethod,
  EIP1193UserRejectedRequest: () => EIP1193UserRejectedRequest,
  ERROR_CODES: () => ERROR_CODES,
  ErrorBase: () => ErrorBase,
  ErrorClassMap: () => ErrorClassMap,
  FUNCTION: () => FUNCTION,
  HDNODE: () => HDNODE,
  HTTPClientError: () => HTTPClientError,
  HTTP_CLIENT: () => HTTP_CLIENT,
  InvalidAbiDataToDecodeError: () => InvalidAbiDataToDecodeError,
  InvalidAbiDataToEncodeError: () => InvalidAbiDataToEncodeError,
  InvalidAbiEventError: () => InvalidAbiEventError,
  InvalidAbiFormatTypeError: () => InvalidAbiFormatTypeError,
  InvalidAbiFunctionError: () => InvalidAbiFunctionError,
  InvalidAddressError: () => InvalidAddressError,
  InvalidBloomError: () => InvalidBloomError,
  InvalidDataReturnTypeError: () => InvalidDataReturnTypeError,
  InvalidDataTypeError: () => InvalidDataTypeError,
  InvalidHDNodeChaincodeError: () => InvalidHDNodeChaincodeError,
  InvalidHDNodeDerivationPathError: () => InvalidHDNodeDerivationPathError,
  InvalidHDNodeMnemonicsError: () => InvalidHDNodeMnemonicsError,
  InvalidHDNodePrivateKeyError: () => InvalidHDNodePrivateKeyError,
  InvalidHDNodePublicKeyError: () => InvalidHDNodePublicKeyError,
  InvalidKError: () => InvalidKError,
  InvalidKeystoreError: () => InvalidKeystoreError,
  InvalidKeystorePasswordError: () => InvalidKeystorePasswordError,
  InvalidRLPError: () => InvalidRLPError,
  InvalidSecp256k1MessageHashError: () => InvalidSecp256k1MessageHashError,
  InvalidSecp256k1PrivateKeyError: () => InvalidSecp256k1PrivateKeyError,
  InvalidSecp256k1SignatureError: () => InvalidSecp256k1SignatureError,
  InvalidSecp256k1SignatureRecoveryError: () => InvalidSecp256k1SignatureRecoveryError,
  JSONRPC: () => JSONRPC,
  JSONRPCDefaultError: () => JSONRPCDefaultError,
  JSONRPCInternalError: () => JSONRPCInternalError,
  JSONRPCInvalidParams: () => JSONRPCInvalidParams,
  JSONRPCInvalidRequest: () => JSONRPCInvalidRequest,
  KEYSTORE: () => KEYSTORE,
  NotImplementedError: () => NotImplementedError,
  POLL_ERROR: () => POLL_ERROR,
  PollExecutionError: () => PollExecutionError,
  ProviderRpcError: () => ProviderRpcError,
  RLP: () => RLP,
  SECP256K1: () => SECP256K1,
  TRANSACTION: () => TRANSACTION,
  TransactionAlreadySignedError: () => TransactionAlreadySignedError,
  TransactionBodyError: () => TransactionBodyError,
  TransactionDelegationError: () => TransactionDelegationError,
  TransactionMissingPrivateKeyError: () => TransactionMissingPrivateKeyError,
  TransactionNotSignedError: () => TransactionNotSignedError,
  assert: () => assert,
  assertInnerError: () => assertInnerError,
  buildError: () => buildError,
  buildErrorMessage: () => buildErrorMessage,
  buildProviderError: () => buildProviderError,
  errors: () => errors_exports,
  getEIP1193ErrorCode: () => getEIP1193ErrorCode,
  getJSONRPCErrorCode: () => getJSONRPCErrorCode,
  stringifyData: () => stringifyData
});
module.exports = __toCommonJS(src_exports);

// src/errors.ts
var errors_exports = {};
__export(errors_exports, {
  ABI: () => ABI,
  ADDRESS: () => ADDRESS,
  BLOOM: () => BLOOM,
  CERTIFICATE: () => CERTIFICATE,
  CONTRACT: () => CONTRACT,
  CertificateInvalidSignatureFormatError: () => CertificateInvalidSignatureFormatError,
  CertificateInvalidSignerError: () => CertificateInvalidSignerError,
  CertificateNotSignedError: () => CertificateNotSignedError,
  ContractDeploymentFailedError: () => ContractDeploymentFailedError,
  ContractInterfaceError: () => ContractInterfaceError,
  DATA: () => DATA,
  EIP1193: () => EIP1193,
  EIP1193ChainDisconnected: () => EIP1193ChainDisconnected,
  EIP1193Disconnected: () => EIP1193Disconnected,
  EIP1193Unauthorized: () => EIP1193Unauthorized,
  EIP1193UnsupportedMethod: () => EIP1193UnsupportedMethod,
  EIP1193UserRejectedRequest: () => EIP1193UserRejectedRequest,
  ERROR_CODES: () => ERROR_CODES,
  ErrorBase: () => ErrorBase,
  ErrorClassMap: () => ErrorClassMap,
  FUNCTION: () => FUNCTION,
  HDNODE: () => HDNODE,
  HTTPClientError: () => HTTPClientError,
  HTTP_CLIENT: () => HTTP_CLIENT,
  InvalidAbiDataToDecodeError: () => InvalidAbiDataToDecodeError,
  InvalidAbiDataToEncodeError: () => InvalidAbiDataToEncodeError,
  InvalidAbiEventError: () => InvalidAbiEventError,
  InvalidAbiFormatTypeError: () => InvalidAbiFormatTypeError,
  InvalidAbiFunctionError: () => InvalidAbiFunctionError,
  InvalidAddressError: () => InvalidAddressError,
  InvalidBloomError: () => InvalidBloomError,
  InvalidDataReturnTypeError: () => InvalidDataReturnTypeError,
  InvalidDataTypeError: () => InvalidDataTypeError,
  InvalidHDNodeChaincodeError: () => InvalidHDNodeChaincodeError,
  InvalidHDNodeDerivationPathError: () => InvalidHDNodeDerivationPathError,
  InvalidHDNodeMnemonicsError: () => InvalidHDNodeMnemonicsError,
  InvalidHDNodePrivateKeyError: () => InvalidHDNodePrivateKeyError,
  InvalidHDNodePublicKeyError: () => InvalidHDNodePublicKeyError,
  InvalidKError: () => InvalidKError,
  InvalidKeystoreError: () => InvalidKeystoreError,
  InvalidKeystorePasswordError: () => InvalidKeystorePasswordError,
  InvalidRLPError: () => InvalidRLPError,
  InvalidSecp256k1MessageHashError: () => InvalidSecp256k1MessageHashError,
  InvalidSecp256k1PrivateKeyError: () => InvalidSecp256k1PrivateKeyError,
  InvalidSecp256k1SignatureError: () => InvalidSecp256k1SignatureError,
  InvalidSecp256k1SignatureRecoveryError: () => InvalidSecp256k1SignatureRecoveryError,
  JSONRPC: () => JSONRPC,
  JSONRPCDefaultError: () => JSONRPCDefaultError,
  JSONRPCInternalError: () => JSONRPCInternalError,
  JSONRPCInvalidParams: () => JSONRPCInvalidParams,
  JSONRPCInvalidRequest: () => JSONRPCInvalidRequest,
  KEYSTORE: () => KEYSTORE,
  NotImplementedError: () => NotImplementedError,
  POLL_ERROR: () => POLL_ERROR,
  PollExecutionError: () => PollExecutionError,
  ProviderRpcError: () => ProviderRpcError,
  RLP: () => RLP,
  SECP256K1: () => SECP256K1,
  TRANSACTION: () => TRANSACTION,
  TransactionAlreadySignedError: () => TransactionAlreadySignedError,
  TransactionBodyError: () => TransactionBodyError,
  TransactionDelegationError: () => TransactionDelegationError,
  TransactionMissingPrivateKeyError: () => TransactionMissingPrivateKeyError,
  TransactionNotSignedError: () => TransactionNotSignedError,
  assert: () => assert,
  assertInnerError: () => assertInnerError,
  buildError: () => buildError,
  buildErrorMessage: () => buildErrorMessage,
  buildProviderError: () => buildProviderError,
  getEIP1193ErrorCode: () => getEIP1193ErrorCode,
  getJSONRPCErrorCode: () => getJSONRPCErrorCode,
  stringifyData: () => stringifyData
});

// src/model/base.ts
var ErrorBase = class extends Error {
  code;
  message;
  data;
  innerError;
  constructor({
    code,
    message,
    data,
    innerError
  }) {
    super();
    this.code = code;
    this.message = message;
    this.data = data;
    this.innerError = innerError;
  }
};

// src/model/core/abi.ts
var InvalidAbiDataToDecodeError = class extends ErrorBase {
};
var InvalidAbiDataToEncodeError = class extends ErrorBase {
};
var InvalidAbiEventError = class extends ErrorBase {
};
var InvalidAbiFormatTypeError = class extends ErrorBase {
};
var InvalidAbiFunctionError = class extends ErrorBase {
};
var ContractInterfaceError = class extends ErrorBase {
};
var ABI = /* @__PURE__ */ ((ABI2) => {
  ABI2["INVALID_FUNCTION"] = "INVALID_FUNCTION";
  ABI2["INVALID_EVENT"] = "INVALID_EVENT";
  ABI2["INVALID_DATA_TO_DECODE"] = "INVALID_DATA_TO_DECODE";
  ABI2["INVALID_DATA_TO_ENCODE"] = "INVALID_DATA_TO_ENCODE";
  ABI2["INVALID_FORMAT_TYPE"] = "INVALID_FORMAT_TYPE";
  ABI2["CONTRACT_INTERFACE_ERROR"] = "CONTRACT_INTERFACE_ERROR";
  return ABI2;
})(ABI || {});

// src/model/core/address.ts
var InvalidAddressError = class extends ErrorBase {
};
var ADDRESS = /* @__PURE__ */ ((ADDRESS2) => {
  ADDRESS2["INVALID_ADDRESS"] = "INVALID_ADDRESS";
  return ADDRESS2;
})(ADDRESS || {});

// src/model/core/bloom.ts
var InvalidBloomError = class extends ErrorBase {
};
var InvalidKError = class extends ErrorBase {
};
var BLOOM = /* @__PURE__ */ ((BLOOM2) => {
  BLOOM2["INVALID_BLOOM"] = "INVALID_BLOOM";
  BLOOM2["INVALID_K"] = "INVALID_K";
  return BLOOM2;
})(BLOOM || {});

// src/model/core/certificate.ts
var CertificateNotSignedError = class extends ErrorBase {
};
var CertificateInvalidSignatureFormatError = class extends ErrorBase {
};
var CertificateInvalidSignerError = class extends ErrorBase {
};
var CERTIFICATE = /* @__PURE__ */ ((CERTIFICATE2) => {
  CERTIFICATE2["CERTIFICATE_NOT_SIGNED"] = "CERTIFICATE_NOT_SIGNED";
  CERTIFICATE2["CERTIFICATE_INVALID_SIGNATURE_FORMAT"] = "CERTIFICATE_INVALID_SIGNATURE_FORMAT";
  CERTIFICATE2["CERTIFICATE_INVALID_SIGNER"] = "CERTIFICATE_INVALID_SIGNER";
  return CERTIFICATE2;
})(CERTIFICATE || {});

// src/model/core/contract.ts
var ContractDeploymentFailedError = class extends ErrorBase {
};
var CONTRACT = /* @__PURE__ */ ((CONTRACT2) => {
  CONTRACT2["CONTRACT_DEPLOYMENT_FAILED"] = "CONTRACT_DEPLOYMENT_FAILED";
  return CONTRACT2;
})(CONTRACT || {});

// src/model/core/data.ts
var InvalidDataTypeError = class extends ErrorBase {
};
var InvalidDataReturnTypeError = class extends ErrorBase {
};
var DATA = /* @__PURE__ */ ((DATA2) => {
  DATA2["INVALID_DATA_TYPE"] = "INVALID_DATA_TYPE";
  DATA2["INVALID_DATA_RETURN_TYPE"] = "INVALID_DATA_RETURN_TYPE";
  return DATA2;
})(DATA || {});

// src/model/core/hdnode.ts
var InvalidHDNodeChaincodeError = class extends ErrorBase {
};
var InvalidHDNodeMnemonicsError = class extends ErrorBase {
};
var InvalidHDNodePrivateKeyError = class extends ErrorBase {
};
var InvalidHDNodePublicKeyError = class extends ErrorBase {
};
var InvalidHDNodeDerivationPathError = class extends ErrorBase {
};
var HDNODE = /* @__PURE__ */ ((HDNODE2) => {
  HDNODE2["INVALID_HDNODE_PUBLIC_KEY"] = "INVALID_HDNODE_PUBLIC_KEY";
  HDNODE2["INVALID_HDNODE_PRIVATE_KEY"] = "INVALID_HDNODE_PRIVATE_KEY";
  HDNODE2["INVALID_HDNODE_CHAIN_CODE"] = "INVALID_HDNODE_CHAIN_CODE";
  HDNODE2["INVALID_HDNODE_MNEMONICS"] = "INVALID_HDNODE_MNEMONICS";
  HDNODE2["INVALID_HDNODE_DERIVATION_PATH"] = "INVALID_HDNODE_DERIVATION_PATH";
  return HDNODE2;
})(HDNODE || {});

// src/model/core/keystore.ts
var InvalidKeystoreError = class extends ErrorBase {
};
var InvalidKeystorePasswordError = class extends ErrorBase {
};
var KEYSTORE = /* @__PURE__ */ ((KEYSTORE2) => {
  KEYSTORE2["INVALID_KEYSTORE"] = "INVALID_KEYSTORE";
  KEYSTORE2["INVALID_PASSWORD"] = "INVALID_PASSWORD";
  return KEYSTORE2;
})(KEYSTORE || {});

// src/model/core/rlp.ts
var InvalidRLPError = class extends ErrorBase {
};
var RLP = /* @__PURE__ */ ((RLP2) => {
  RLP2["INVALID_RLP"] = "INVALID_RLP";
  return RLP2;
})(RLP || {});

// src/model/core/secp256k1.ts
var InvalidSecp256k1PrivateKeyError = class extends ErrorBase {
};
var InvalidSecp256k1MessageHashError = class extends ErrorBase {
};
var InvalidSecp256k1SignatureError = class extends ErrorBase {
};
var InvalidSecp256k1SignatureRecoveryError = class extends ErrorBase {
};
var SECP256K1 = /* @__PURE__ */ ((SECP256K12) => {
  SECP256K12["INVALID_SECP256k1_PRIVATE_KEY"] = "INVALID_SECP256k1_PRIVATE_KEY";
  SECP256K12["INVALID_SECP256k1_MESSAGE_HASH"] = "INVALID_SECP256k1_MESSAGE_HASH";
  SECP256K12["INVALID_SECP256k1_SIGNATURE"] = "INVALID_SECP256k1_SIGNATURE";
  SECP256K12["INVALID_SECP256k1_SIGNATURE_RECOVERY"] = "INVALID_SECP256k1_SIGNATURE_RECOVERY";
  return SECP256K12;
})(SECP256K1 || {});

// src/model/core/transaction.ts
var TransactionAlreadySignedError = class extends ErrorBase {
};
var TransactionNotSignedError = class extends ErrorBase {
};
var TransactionBodyError = class extends ErrorBase {
};
var TransactionDelegationError = class extends ErrorBase {
};
var TransactionMissingPrivateKeyError = class extends ErrorBase {
};
var TRANSACTION = /* @__PURE__ */ ((TRANSACTION2) => {
  TRANSACTION2["ALREADY_SIGNED"] = "ALREADY_SIGNED";
  TRANSACTION2["NOT_SIGNED"] = "NOT_SIGNED";
  TRANSACTION2["INVALID_TRANSACTION_BODY"] = "INVALID_TRANSACTION_BODY";
  TRANSACTION2["INVALID_DELEGATION"] = "INVALID_DELEGATION";
  TRANSACTION2["MISSING_PRIVATE_KEY"] = "MISSING_PRIVATE_KEY";
  return TRANSACTION2;
})(TRANSACTION || {});

// src/model/network/http-client.ts
var HTTPClientError = class extends ErrorBase {
};
var HTTP_CLIENT = /* @__PURE__ */ ((HTTP_CLIENT2) => {
  HTTP_CLIENT2["INVALID_HTTP_REQUEST"] = "INVALID_HTTP_REQUEST";
  return HTTP_CLIENT2;
})(HTTP_CLIENT || {});

// src/model/network/poll.ts
var PollExecutionError = class extends ErrorBase {
};
var POLL_ERROR = /* @__PURE__ */ ((POLL_ERROR2) => {
  POLL_ERROR2["POLL_EXECUTION_ERROR"] = "POLL_EXECUTION_ERROR";
  return POLL_ERROR2;
})(POLL_ERROR || {});

// src/model/generic/function.ts
var NotImplementedError = class extends ErrorBase {
};
var FUNCTION = /* @__PURE__ */ ((FUNCTION2) => {
  FUNCTION2["NOT_IMPLEMENTED"] = "NOT_IMPLEMENTED";
  return FUNCTION2;
})(FUNCTION || {});

// src/model/eip1193/eip1193.ts
var EIP1193UserRejectedRequest = class extends ErrorBase {
};
var EIP1193Unauthorized = class extends ErrorBase {
};
var EIP1193UnsupportedMethod = class extends ErrorBase {
};
var EIP1193Disconnected = class extends ErrorBase {
};
var EIP1193ChainDisconnected = class extends ErrorBase {
};
var EIP1193 = /* @__PURE__ */ ((EIP11932) => {
  EIP11932["USER_REJECTED_REQUEST"] = "USER_REJECTED_REQUEST";
  EIP11932["UNAUTHORIZED"] = "UNAUTHORIZED";
  EIP11932["UNSUPPORTED_METHOD"] = "UNSUPPORTED METHOD";
  EIP11932["DISCONNECTED"] = "DISCONNECTED";
  EIP11932["CHAIN_DISCONNECTED"] = "CHAIN_DISCONNECTED";
  return EIP11932;
})(EIP1193 || {});
var getEIP1193ErrorCode = (error) => {
  switch (error) {
    case "USER_REJECTED_REQUEST" /* USER_REJECTED_REQUEST */:
      return 4001;
    case "UNAUTHORIZED" /* UNAUTHORIZED */:
      return 4100;
    case "UNSUPPORTED METHOD" /* UNSUPPORTED_METHOD */:
      return 4200;
    case "DISCONNECTED" /* DISCONNECTED */:
      return 4900;
    case "CHAIN_DISCONNECTED" /* CHAIN_DISCONNECTED */:
      return 4901;
  }
};

// src/model/json-rpc/json-rpc.ts
var JSONRPCInvalidRequest = class extends ErrorBase {
};
var JSONRPCInvalidParams = class extends ErrorBase {
};
var JSONRPCInternalError = class extends ErrorBase {
};
var JSONRPCDefaultError = class extends ErrorBase {
};
var JSONRPC = /* @__PURE__ */ ((JSONRPC2) => {
  JSONRPC2["INVALID_REQUEST"] = "INVALID_REQUEST";
  JSONRPC2["INVALID_PARAMS"] = "INVALID_PARAMS";
  JSONRPC2["INTERNAL_ERROR"] = "INTERNAL_ERROR";
  JSONRPC2["DEFAULT"] = "DEFAULT";
  return JSONRPC2;
})(JSONRPC || {});
var getJSONRPCErrorCode = (error) => {
  switch (error) {
    case "INVALID_REQUEST" /* INVALID_REQUEST */:
      return -32600;
    case "INVALID_PARAMS" /* INVALID_PARAMS */:
      return -32602;
    case "INTERNAL_ERROR" /* INTERNAL_ERROR */:
      return -32603;
    case "DEFAULT" /* DEFAULT */:
      return -32e3;
  }
};

// src/model/provider-rpc.ts
var ProviderRpcError = class extends Error {
  /**
   * The error code as specified in EIP-1193 or EIP-1474
   *
   * @link [EIP-1474](https://eips.ethereum.org/EIPS/eip-1474#error-codes)
   * @link [EIP-1193](https://eips.ethereum.org/EIPS/eip-1193#provider-errors)
   */
  code;
  /**
   * Contains optional extra information about the error
   */
  data;
  /**
   *
   * @param code - The error code as specified in EIP-1193 or EIP-1474
   * @param message - The error message
   * @param data - Contains optional extra information about the error
   */
  constructor(code, message, data) {
    super(message);
    this.code = code;
    this.data = data;
  }
};

// src/types/errorTypes.ts
var ERROR_CODES = {
  SECP256K1,
  ADDRESS,
  KEYSTORE,
  HDNODE,
  BLOOM,
  CERTIFICATE,
  ABI,
  RLP,
  DATA,
  TRANSACTION,
  HTTP_CLIENT,
  POLL_ERROR,
  FUNCTION,
  EIP1193,
  JSONRPC,
  CONTRACT
};
var ErrorClassMap = /* @__PURE__ */ new Map([
  // ADDRESS
  ["INVALID_ADDRESS" /* INVALID_ADDRESS */, InvalidAddressError],
  // SECP256K1
  ["INVALID_SECP256k1_PRIVATE_KEY" /* INVALID_SECP256k1_PRIVATE_KEY */, InvalidSecp256k1PrivateKeyError],
  [
    "INVALID_SECP256k1_MESSAGE_HASH" /* INVALID_SECP256k1_MESSAGE_HASH */,
    InvalidSecp256k1MessageHashError
  ],
  ["INVALID_SECP256k1_SIGNATURE" /* INVALID_SECP256k1_SIGNATURE */, InvalidSecp256k1SignatureError],
  [
    "INVALID_SECP256k1_SIGNATURE_RECOVERY" /* INVALID_SECP256k1_SIGNATURE_RECOVERY */,
    InvalidSecp256k1SignatureRecoveryError
  ],
  // KEYSTORE
  ["INVALID_KEYSTORE" /* INVALID_KEYSTORE */, InvalidKeystoreError],
  ["INVALID_PASSWORD" /* INVALID_PASSWORD */, InvalidKeystorePasswordError],
  // HDNODE
  ["INVALID_HDNODE_CHAIN_CODE" /* INVALID_HDNODE_CHAIN_CODE */, InvalidHDNodeChaincodeError],
  ["INVALID_HDNODE_MNEMONICS" /* INVALID_HDNODE_MNEMONICS */, InvalidHDNodeMnemonicsError],
  ["INVALID_HDNODE_PRIVATE_KEY" /* INVALID_HDNODE_PRIVATE_KEY */, InvalidHDNodePrivateKeyError],
  ["INVALID_HDNODE_PUBLIC_KEY" /* INVALID_HDNODE_PUBLIC_KEY */, InvalidHDNodePublicKeyError],
  ["INVALID_HDNODE_DERIVATION_PATH" /* INVALID_HDNODE_DERIVATION_PATH */, InvalidHDNodeDerivationPathError],
  ["INVALID_BLOOM" /* INVALID_BLOOM */, InvalidBloomError],
  ["INVALID_K" /* INVALID_K */, InvalidKError],
  // CERTIFICATE
  ["CERTIFICATE_NOT_SIGNED" /* CERTIFICATE_NOT_SIGNED */, CertificateNotSignedError],
  [
    "CERTIFICATE_INVALID_SIGNATURE_FORMAT" /* CERTIFICATE_INVALID_SIGNATURE_FORMAT */,
    CertificateInvalidSignatureFormatError
  ],
  ["CERTIFICATE_INVALID_SIGNER" /* CERTIFICATE_INVALID_SIGNER */, CertificateInvalidSignerError],
  // ABI
  ["INVALID_EVENT" /* INVALID_EVENT */, InvalidAbiEventError],
  ["INVALID_DATA_TO_DECODE" /* INVALID_DATA_TO_DECODE */, InvalidAbiDataToDecodeError],
  ["INVALID_DATA_TO_ENCODE" /* INVALID_DATA_TO_ENCODE */, InvalidAbiDataToEncodeError],
  ["INVALID_FORMAT_TYPE" /* INVALID_FORMAT_TYPE */, InvalidAbiFormatTypeError],
  ["INVALID_FUNCTION" /* INVALID_FUNCTION */, InvalidAbiFunctionError],
  ["CONTRACT_INTERFACE_ERROR" /* CONTRACT_INTERFACE_ERROR */, ContractInterfaceError],
  // RLP
  ["INVALID_RLP" /* INVALID_RLP */, InvalidRLPError],
  // DATA
  ["INVALID_DATA_TYPE" /* INVALID_DATA_TYPE */, InvalidDataTypeError],
  ["INVALID_DATA_RETURN_TYPE" /* INVALID_DATA_RETURN_TYPE */, InvalidDataReturnTypeError],
  // TRANSACTION
  ["ALREADY_SIGNED" /* ALREADY_SIGNED */, TransactionAlreadySignedError],
  ["NOT_SIGNED" /* NOT_SIGNED */, TransactionNotSignedError],
  ["INVALID_TRANSACTION_BODY" /* INVALID_TRANSACTION_BODY */, TransactionBodyError],
  ["INVALID_DELEGATION" /* INVALID_DELEGATION */, TransactionDelegationError],
  ["MISSING_PRIVATE_KEY" /* MISSING_PRIVATE_KEY */, TransactionMissingPrivateKeyError],
  // HTTP_CLIENT
  ["INVALID_HTTP_REQUEST" /* INVALID_HTTP_REQUEST */, HTTPClientError],
  // POLL_ERROR
  ["POLL_EXECUTION_ERROR" /* POLL_EXECUTION_ERROR */, PollExecutionError],
  // FUNCTION
  ["NOT_IMPLEMENTED" /* NOT_IMPLEMENTED */, NotImplementedError],
  // EIP1193
  ["USER_REJECTED_REQUEST" /* USER_REJECTED_REQUEST */, EIP1193UserRejectedRequest],
  ["UNAUTHORIZED" /* UNAUTHORIZED */, EIP1193Unauthorized],
  ["UNSUPPORTED METHOD" /* UNSUPPORTED_METHOD */, EIP1193UnsupportedMethod],
  ["DISCONNECTED" /* DISCONNECTED */, EIP1193Disconnected],
  ["CHAIN_DISCONNECTED" /* CHAIN_DISCONNECTED */, EIP1193ChainDisconnected],
  // JSONRPC
  ["INVALID_REQUEST" /* INVALID_REQUEST */, JSONRPCInvalidRequest],
  ["INVALID_PARAMS" /* INVALID_PARAMS */, JSONRPCInvalidParams],
  ["INTERNAL_ERROR" /* INTERNAL_ERROR */, JSONRPCInternalError],
  ["DEFAULT" /* DEFAULT */, JSONRPCDefaultError],
  // CONTRACT
  ["CONTRACT_DEPLOYMENT_FAILED" /* CONTRACT_DEPLOYMENT_FAILED */, ContractDeploymentFailedError]
]);

// src/utils/error-message-builder/error-message-builder.ts
var stringifyData = (data) => {
  const getCircularReplacer = () => {
    const seen = /* @__PURE__ */ new WeakSet();
    return (key, value) => {
      if (typeof value === "object" && value !== null) {
        if (seen.has(value)) {
          return;
        }
        seen.add(value);
      }
      return value;
    };
  };
  return JSON.stringify(data, getCircularReplacer());
};
function buildErrorMessage(methodName, errorMessage, inputData, innerError) {
  return `Method '${methodName}' failed.
-Reason: '${errorMessage}'
-Parameters: 
	${stringifyData(inputData)}
-Internal error: 
	${innerError?.message !== void 0 ? innerError.message : "No internal error given"}`;
}

// src/utils/assert/assert.ts
function assert(methodName, condition, code, message, data, innerError) {
  const error = buildError(
    `Assertion on ${methodName}`,
    code,
    message,
    data,
    innerError
  );
  if (!condition && typeof error === "object")
    throw error;
}
function assertInnerError(error) {
  if (error instanceof Error) {
    return error;
  }
  return new Error(
    `Inner error is not an instance of Error. Object:
	${stringifyData(error)}`
  );
}

// src/utils/error-builder/error-builder.ts
function buildError(methodName, code, message, data, innerError) {
  const ErrorClass = ErrorClassMap.get(code);
  if (ErrorClass === void 0 || ErrorClass === null) {
    throw new Error("Invalid error code");
  }
  const errorMessage = buildErrorMessage(
    methodName,
    message,
    data,
    innerError === void 0 ? void 0 : assertInnerError(innerError)
  );
  const error = new ErrorClass({
    code,
    message: errorMessage,
    data,
    innerError: innerError === void 0 ? void 0 : assertInnerError(innerError)
  });
  return error;
}

// src/utils/error-builder/provider-error-builder.ts
function buildProviderError(code, message, data) {
  const ErrorClass = ErrorClassMap.get(code);
  const _code = isEIP1193Code(code) ? getEIP1193ErrorCode(code) : getJSONRPCErrorCode(code);
  if (ErrorClass === void 0 || ErrorClass === null) {
    throw new Error("Invalid error code");
  }
  return new ProviderRpcError(_code, message, data);
}
function isEIP1193Code(code) {
  return Object.values(EIP1193).includes(code);
}
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  ABI,
  ADDRESS,
  BLOOM,
  CERTIFICATE,
  CONTRACT,
  CertificateInvalidSignatureFormatError,
  CertificateInvalidSignerError,
  CertificateNotSignedError,
  ContractDeploymentFailedError,
  ContractInterfaceError,
  DATA,
  EIP1193,
  EIP1193ChainDisconnected,
  EIP1193Disconnected,
  EIP1193Unauthorized,
  EIP1193UnsupportedMethod,
  EIP1193UserRejectedRequest,
  ERROR_CODES,
  ErrorBase,
  ErrorClassMap,
  FUNCTION,
  HDNODE,
  HTTPClientError,
  HTTP_CLIENT,
  InvalidAbiDataToDecodeError,
  InvalidAbiDataToEncodeError,
  InvalidAbiEventError,
  InvalidAbiFormatTypeError,
  InvalidAbiFunctionError,
  InvalidAddressError,
  InvalidBloomError,
  InvalidDataReturnTypeError,
  InvalidDataTypeError,
  InvalidHDNodeChaincodeError,
  InvalidHDNodeDerivationPathError,
  InvalidHDNodeMnemonicsError,
  InvalidHDNodePrivateKeyError,
  InvalidHDNodePublicKeyError,
  InvalidKError,
  InvalidKeystoreError,
  InvalidKeystorePasswordError,
  InvalidRLPError,
  InvalidSecp256k1MessageHashError,
  InvalidSecp256k1PrivateKeyError,
  InvalidSecp256k1SignatureError,
  InvalidSecp256k1SignatureRecoveryError,
  JSONRPC,
  JSONRPCDefaultError,
  JSONRPCInternalError,
  JSONRPCInvalidParams,
  JSONRPCInvalidRequest,
  KEYSTORE,
  NotImplementedError,
  POLL_ERROR,
  PollExecutionError,
  ProviderRpcError,
  RLP,
  SECP256K1,
  TRANSACTION,
  TransactionAlreadySignedError,
  TransactionBodyError,
  TransactionDelegationError,
  TransactionMissingPrivateKeyError,
  TransactionNotSignedError,
  assert,
  assertInnerError,
  buildError,
  buildErrorMessage,
  buildProviderError,
  errors,
  getEIP1193ErrorCode,
  getJSONRPCErrorCode,
  stringifyData
});
