"use strict";
var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/index.ts
var src_exports = {};
__export(src_exports, {
  AccountsModule: () => AccountsModule,
  BlocksModule: () => BlocksModule,
  Contract: () => Contract,
  ContractFactory: () => ContractFactory,
  ContractsModule: () => ContractsModule,
  DEFAULT_HTTP_TIMEOUT: () => DEFAULT_HTTP_TIMEOUT,
  DelegationHandler: () => DelegationHandler,
  HTTPS_REGEX: () => HTTPS_REGEX,
  HTTP_REGEX: () => HTTP_REGEX,
  HttpClient: () => HttpClient,
  LogsModule: () => LogsModule,
  NODE_HEALTHCHECK_TOLERANCE_IN_SECONDS: () => NODE_HEALTHCHECK_TOLERANCE_IN_SECONDS,
  NodesModule: () => NodesModule,
  Poll: () => Poll,
  ThorClient: () => ThorClient,
  TransactionsModule: () => TransactionsModule,
  buildQuery: () => buildQuery,
  convertError: () => convertError,
  network: () => network_exports,
  sanitizeWebsocketBaseURL: () => sanitizeWebsocketBaseURL,
  subscriptions: () => subscriptions,
  thorest: () => thorest,
  toQueryString: () => toQueryString
});
module.exports = __toCommonJS(src_exports);

// src/network.ts
var network_exports = {};
__export(network_exports, {
  AccountsModule: () => AccountsModule,
  BlocksModule: () => BlocksModule,
  Contract: () => Contract,
  ContractFactory: () => ContractFactory,
  ContractsModule: () => ContractsModule,
  DEFAULT_HTTP_TIMEOUT: () => DEFAULT_HTTP_TIMEOUT,
  DelegationHandler: () => DelegationHandler,
  HTTPS_REGEX: () => HTTPS_REGEX,
  HTTP_REGEX: () => HTTP_REGEX,
  HttpClient: () => HttpClient,
  LogsModule: () => LogsModule,
  NODE_HEALTHCHECK_TOLERANCE_IN_SECONDS: () => NODE_HEALTHCHECK_TOLERANCE_IN_SECONDS,
  NodesModule: () => NodesModule,
  Poll: () => Poll,
  ThorClient: () => ThorClient,
  TransactionsModule: () => TransactionsModule,
  buildQuery: () => buildQuery,
  convertError: () => convertError,
  sanitizeWebsocketBaseURL: () => sanitizeWebsocketBaseURL,
  subscriptions: () => subscriptions,
  thorest: () => thorest,
  toQueryString: () => toQueryString
});

// src/thor-client/accounts/accounts-module.ts
var import_sdk_errors7 = require("@vechain/sdk-errors");

// src/utils/const/client/http-client.ts
var DEFAULT_HTTP_TIMEOUT = 3e4;
var HTTP_REGEX = /^http:\/\//;
var HTTPS_REGEX = /^https:\/\//;

// src/utils/const/client/nodes.ts
var NODE_HEALTHCHECK_TOLERANCE_IN_SECONDS = 30;

// src/utils/helpers/errors.ts
var import_sdk_errors = require("@vechain/sdk-errors");
var convertError = (error) => {
  if (error.response != null) {
    const resp = error.response;
    return (0, import_sdk_errors.buildError)(
      "convertError",
      import_sdk_errors.HTTP_CLIENT.INVALID_HTTP_REQUEST,
      `An error occurred while performing http request ${error.config?.url}`,
      {
        status: resp.status,
        method: error.config?.method,
        url: error.config?.url,
        text: (0, import_sdk_errors.stringifyData)(resp.data)
      }
    );
  } else {
    return (0, import_sdk_errors.buildError)(
      "convertError",
      import_sdk_errors.HTTP_CLIENT.INVALID_HTTP_REQUEST,
      `An error occurred while performing http request ${error.config?.url}`,
      {
        method: error.config?.method,
        url: error.config?.url,
        message: error.message
      }
    );
  }
};

// src/utils/helpers/request.ts
var buildQuery = (params) => {
  const definedParams = {};
  for (const key in params) {
    if (params[key] !== void 0) {
      definedParams[key] = params[key];
    }
  }
  return definedParams;
};

// src/utils/http/http-client.ts
var import_axios = __toESM(require("axios"));
var import_http = require("http");
var import_https = require("https");
var import_sdk_errors2 = require("@vechain/sdk-errors");
var HttpClient = class {
  /**
   * Instantiates an `HttpClient` object with a specified base URL and HTTP request timeout.
   *
   * @param baseURL - The base URL for all network requests.
   * @param options - (Optional) An object containing additional configuration options for the HTTP client, such as a custom Axios instance and a request timeout.
   */
  constructor(baseURL, options) {
    this.baseURL = baseURL;
    this.axios = options?.axiosInstance ?? import_axios.default.create({
      httpAgent: new import_http.Agent({ keepAlive: false }),
      httpsAgent: new import_https.Agent({ keepAlive: false }),
      baseURL,
      timeout: options?.timeout ?? DEFAULT_HTTP_TIMEOUT
    });
  }
  /**
   * Axios instance to make http requests
   */
  axios;
  /**
   * Sends an HTTP request using the Axios library.
   *
   * @param method - The HTTP method to be used ('GET' or 'POST').
   * @param path - The path to access on the server relative to the base URL.
   * @param params - (Optional) Additional request parameters such as query parameters, request body, and custom headers.
   * @returns A promise that resolves to the response data from the HTTP request.
   * @throws {HTTPClientError} Will throw an error if the request fails, with more detailed information if the error is Axios-specific.
   */
  async http(method, path, params) {
    const config = {
      method,
      url: path,
      data: params?.body,
      headers: params?.headers,
      params: params?.query
    };
    try {
      const resp = await this.axios(config);
      this.validateResponseHeader(params, resp.headers);
      return resp.data;
    } catch (err) {
      if (import_axios.default.isAxiosError(err)) {
        throw convertError(err);
      }
      throw (0, import_sdk_errors2.buildError)(
        "http",
        import_sdk_errors2.HTTP_CLIENT.INVALID_HTTP_REQUEST,
        "HTTP request failed: Check method, path, and parameters for validity.",
        { method, path, params },
        err
      );
    }
  }
  /**
   * Validates the response headers if a validation function is provided.
   *
   * @param params - (Optional) The request parameters.
   * @param headers - The response headers.
   */
  validateResponseHeader(params, headers) {
    if (params?.validateResponseHeader != null && headers != null) {
      const responseHeaders = {};
      for (const key in headers) {
        const value = headers[key];
        if (typeof value === "string") {
          responseHeaders[key] = value;
        }
      }
      params.validateResponseHeader(responseHeaders);
    }
  }
};

// src/utils/poll/sync.ts
var import_sdk_errors4 = require("@vechain/sdk-errors");

// src/utils/poll/helpers/assertions.ts
var import_sdk_errors3 = require("@vechain/sdk-errors");
function assertPositiveIntegerForPollOptions(methodName, field, fieldName) {
  (0, import_sdk_errors3.assert)(
    `assertPositiveIntegerForPollOptions - ${methodName}`,
    field === void 0 || field > 0 && Number.isInteger(field),
    import_sdk_errors3.DATA.INVALID_DATA_TYPE,
    `Invalid input for field name. ${fieldName} must be a positive number`,
    { field }
  );
}

// src/utils/poll/sync.ts
async function sleep(delayInMilliseconds) {
  await new Promise((resolve) => setTimeout(resolve, delayInMilliseconds));
}
function SyncPoll(pollingFunction, options) {
  assertPositiveIntegerForPollOptions(
    "SyncPoll",
    options?.requestIntervalInMilliseconds,
    "options?.requestIntervalInMilliseconds"
  );
  assertPositiveIntegerForPollOptions(
    "SyncPoll",
    options?.maximumIterations,
    "options?.maximumIterations"
  );
  assertPositiveIntegerForPollOptions(
    "SyncPoll",
    options?.maximumWaitingTimeInMilliseconds,
    "options?.maximumWaitingTimeInMilliseconds"
  );
  let currentIteration = 0;
  let currentResult;
  let pollingCondition = false;
  const startTime = Date.now();
  return {
    /**
     * Poll until the condition is met.
     *
     * @param condition - The condition to be met.
     * @returns The result of the poll after the condition is met.
     */
    waitUntil: async (condition) => {
      try {
        do {
          currentResult = await pollingFunction();
          await sleep(
            options?.requestIntervalInMilliseconds !== void 0 ? options.requestIntervalInMilliseconds : 1e3
          );
          currentIteration = currentIteration + 1;
          const isConditionSatisfied = condition(currentResult);
          const isMaximumIterationsReached = options?.maximumIterations !== void 0 ? currentIteration >= options.maximumIterations : false;
          const isTimeLimitReached = options?.maximumWaitingTimeInMilliseconds !== void 0 && Date.now() - startTime >= options.maximumWaitingTimeInMilliseconds;
          pollingCondition = !(isConditionSatisfied || isMaximumIterationsReached || isTimeLimitReached);
        } while (pollingCondition);
        return currentResult;
      } catch (error) {
        throw (0, import_sdk_errors4.buildError)(
          "SyncPoll - waitUntil",
          import_sdk_errors4.POLL_ERROR.POLL_EXECUTION_ERROR,
          "Polling failed: Function execution error encountered during synchronous polling.",
          {
            functionName: pollingFunction.name
          },
          error
        );
      }
    }
  };
}

// src/utils/poll/event.ts
var import_events = require("events");
var import_sdk_errors5 = require("@vechain/sdk-errors");
var EventPoll = class extends import_events.EventEmitter {
  /**
   * The current iteration. It counts how many iterations have been done.
   * This parameter is useful to know how many iterations have been done.
   * For example, it can be used to stop the poll after a certain number of iterations.
   */
  currentIteration = 0;
  /**
   * Error thrown during the execution of the poll.
   */
  error;
  /**
   * Indicates whether to stop execution on error of the
   * {@link _intervalLoop} function.
   *
   * @type {boolean}
   */
  hasToStopOnError;
  /**
   * The interval used to poll.
   */
  intervalId;
  /**
   * The function to be called.
   */
  pollingFunction;
  /**
   * The interval of time (in milliseconds) between each request.
   */
  requestIntervalInMilliseconds;
  /**
   * Constructor for creating an instance of EventPoll.
   *
   * @param {Function} pollingFunction - The function to be executed repeatedly.
   * @param {number} requestIntervalInMilliseconds - The interval in milliseconds between each execution of the polling function.
   * @param {boolean} [hasToStopOnError=true] - Indicates whether to stop polling if an error occurs.
   */
  constructor(pollingFunction, requestIntervalInMilliseconds, hasToStopOnError) {
    super();
    this.pollingFunction = pollingFunction;
    this.hasToStopOnError = hasToStopOnError;
    assertPositiveIntegerForPollOptions(
      "EventPoll constructor",
      requestIntervalInMilliseconds,
      "requestIntervalInMilliseconds"
    );
    this.requestIntervalInMilliseconds = requestIntervalInMilliseconds;
  }
  /**
   * Get how many iterations have been done.
   *
   * @returns The number of iterations.
   */
  get getCurrentIteration() {
    return this.currentIteration;
  }
  /**
   * Basic interval loop function.
   * This function must be called into setInterval.
   * It calls the promise and emit the event.
   */
  async _intervalLoop() {
    try {
      const data = await this.pollingFunction();
      this.emit("data", { data, eventPoll: this });
    } catch (error) {
      this.error = (0, import_sdk_errors5.buildError)(
        "EventPoll - main interval loop function",
        import_sdk_errors5.POLL_ERROR.POLL_EXECUTION_ERROR,
        "Error during the execution of the poll",
        {
          message: error.message,
          functionName: this.pollingFunction.name
        }
      );
      this.emit("error", { error: this.error });
      if (this.hasToStopOnError) {
        this.stopListen();
      }
    }
    this.currentIteration = this.currentIteration + 1;
  }
  /**
   * Listen to the 'data' event.
   * This method is the redefinition of the EventEmitter.on method.
   * Because the EventEmitter.on method does not allow to specify the type of the data.
   * And we must be type safe.
   *
   * This is equivalent to:
   *
   * ```typescript
   * eventPoll.on('data', (data) => { ... });
   * ```
   * @param onDataCallback - The callback to be called when the event is emitted.
   */
  onData(onDataCallback) {
    this.on("data", (data) => {
      onDataCallback(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        data.data,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        data.eventPoll
      );
    });
    return this;
  }
  /* --- Overloaded of 'on' event emitter start --- */
  /**
   * Listen to the 'error' event.
   * This method is the redefinition of the EventEmitter.on method.
   * Because the EventEmitter.on method does not allow to specify the type of the data.
   * And we must be type safe.
   *
   * This is equivalent to:
   *
   * ```typescript
   * eventPoll.on('error', (data) => { ... });
   * ```
   * @param onErrorCallback - The callback to be called when the event is emitted.
   */
  onError(onErrorCallback) {
    this.on("error", (error) => {
      onErrorCallback(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        error.error
      );
    });
    return this;
  }
  /**
   * Listen to the 'start' event.
   * This happens when the poll is stopped.
   *
   * @param onStartCallback - The callback to be called when the event is emitted.
   */
  onStart(onStartCallback) {
    this.on("start", (data) => {
      onStartCallback(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        data.eventPoll
      );
    });
    return this;
  }
  /**
   * Listen to the 'stop' event.
   * This happens when the poll is stopped.
   *
   * @param onStopCallback - The callback to be called when the event is emitted.
   */
  onStop(onStopCallback) {
    this.on("stop", (data) => {
      onStopCallback(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        data.eventPoll
      );
    });
    return this;
  }
  /**
   * Start listening to the event.
   */
  startListen() {
    this.emit("start", { eventPoll: this });
    void this._intervalLoop().then(() => {
      this.intervalId = setInterval(() => {
        void (async () => {
          await this._intervalLoop();
        })();
      }, this.requestIntervalInMilliseconds);
    });
  }
  /**
   * Stop listening to the event.
   */
  stopListen() {
    clearInterval(this.intervalId);
    this.emit("stop", { eventPoll: this });
  }
  /* --- Overloaded of 'on' event emitter end --- */
};
function createEventPoll(callBack, requestIntervalInMilliseconds, hasToStopOnError = true) {
  return new EventPoll(
    callBack,
    requestIntervalInMilliseconds,
    hasToStopOnError
  );
}

// src/utils/poll/index.ts
var Poll = { SyncPoll, createEventPoll };

// src/utils/thorest/helpers.ts
var import_sdk_errors6 = require("@vechain/sdk-errors");
var toQueryString = (params) => {
  const queryParts = Object.entries(params).filter(([, value]) => value !== void 0).map(
    ([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(
      value
    )}`
  );
  return queryParts.length > 0 ? `?${queryParts.join("&")}` : "";
};
var sanitizeWebsocketBaseURL = (url) => {
  url = url.trim();
  const urlRegex = /^(https?:\/\/)([a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*)(:\d+)?\/?$/;
  if (!urlRegex.test(url))
    throw (0, import_sdk_errors6.buildError)(
      "sanitizeWebsocketBaseURL",
      import_sdk_errors6.DATA.INVALID_DATA_TYPE,
      `Invalid url: ${url}. Must adhere to the regex: ${urlRegex}.`
    );
  url = url.replace(/\/$/, "");
  url = HTTP_REGEX.exec(url) !== null ? url.replace(HTTP_REGEX, "ws://") : url.replace(HTTPS_REGEX, "wss://");
  return url;
};

// src/utils/thorest/thorest.ts
var thorest = {
  /**
   * Accounts related endpoints.
   */
  accounts: {
    get: {
      ACCOUNT_DETAIL: (address) => `/accounts/${address}`,
      ACCOUNT_BYTECODE: (address) => `/accounts/${address}/code`,
      STORAGE_AT: (address, position) => `/accounts/${address}/storage/${position}`
    },
    post: {
      SIMULATE_TRANSACTION: (revision) => {
        return revision != null ? `/accounts/*?revision=${revision}` : `/accounts/*`;
      }
    }
  },
  /**
   * Blocks related endpoints.
   */
  blocks: {
    get: {
      BLOCK_DETAIL: (revision) => `/blocks/${revision}`
    }
  },
  /**
   * Nodes related endpoints.
   */
  nodes: {
    get: {
      NODES: () => "/node/network/peers"
    }
  },
  /**
   * Logs related endpoints.
   */
  logs: {
    post: {
      EVENT_LOGS: () => "/logs/event",
      TRANSFER_LOGS: () => "/logs/transfer"
    }
  },
  /**
   * Transactions related endpoints.
   */
  transactions: {
    get: {
      TRANSACTION: (id) => `/transactions/${id}`,
      TRANSACTION_RECEIPT: (id) => `/transactions/${id}/receipt`
    },
    post: {
      TRANSACTION: () => `/transactions`
    }
  },
  /**
   * Subscriptions related endpoints.
   */
  subscriptions: {
    get: {
      /**
       * Subscribe to new blocks.
       *
       * @param baseURL - The URL of the node to request the subscription from.
       * @param position - (optional) The block id to start from, defaults to the best block.
       *
       * @returns The websocket subscription URL.
       */
      BLOCK: (baseURL, position) => {
        const queryParams = toQueryString({
          pos: position
        });
        return `${sanitizeWebsocketBaseURL(
          baseURL
        )}/subscriptions/block${queryParams}`;
      },
      /**
       * Subscribe to new events.
       *
       * @param baseURL - The URL of the node to request the subscription from.
       * @param options - (optional) The options for the subscription.
       *
       * @returns The websocket subscription URL.
       */
      EVENT: (baseURL, options) => {
        const queryParams = toQueryString({
          pos: options?.position,
          addr: options?.contractAddress,
          t0: options?.topic0,
          t1: options?.topic1,
          t2: options?.topic2,
          t3: options?.topic3,
          t4: options?.topic4
        });
        return `${sanitizeWebsocketBaseURL(
          baseURL
        )}/subscriptions/event${queryParams}`;
      },
      /**
       * Subscribe to new VET transfers.
       *
       * @param baseURL - The URL of the node to request the subscription from.
       * @param options - (optional) The options for the subscription.
       *
       * @returns The websocket subscription URL.
       */
      VET_TRANSFER: (baseURL, options) => {
        const queryParams = toQueryString({
          pos: options?.position,
          txOrigin: options?.signerAddress,
          sender: options?.sender,
          recipient: options?.receiver
        });
        return `${sanitizeWebsocketBaseURL(
          baseURL
        )}/subscriptions/transfer${queryParams}`;
      },
      /**
       * Subscribe to new legacy beats.
       * A beat is a notification that a new block has been added to the blockchain with a bloom filter which can be used to check if the block contains any relevant account.
       * @note This subscription has been improved with dynamic size bloom filter with the new `BEAT` subscription.
       *
       * @param baseURL - The URL of the node to request the subscription from.
       * @param position - (optional) The block id to start from, defaults to the best block.
       *
       * @returns The websocket subscription URL.
       */
      BEAT_LEGACY: (baseURL, position) => {
        const queryParams = toQueryString({
          pos: position
        });
        return `${sanitizeWebsocketBaseURL(
          baseURL
        )}/subscriptions/beat${queryParams}`;
      },
      /**
       * Subscribe to new beats.
       * A beat is a notification that a new block has been added to the blockchain with a bloom filter which can be used to check if the block contains any relevant account.
       *
       * @param baseURL - The URL of the node to request the subscription from.
       * @param position - (optional) The block id to start from, defaults to the best block.
       *
       * @returns The websocket subscription URL.
       */
      BEAT: (baseURL, position) => {
        const queryParams = toQueryString({
          pos: position
        });
        return `${sanitizeWebsocketBaseURL(
          baseURL
        )}/subscriptions/beat2${queryParams}`;
      },
      /**
       * Subscribe to new transactions.
       *
       * @returns The websocket subscription URL.
       */
      NEW_TRANSACTIONS: (baseURL) => `${sanitizeWebsocketBaseURL(baseURL)}/subscriptions/txpool`
    }
  },
  /**
   * Debug related endpoints.
   */
  debug: {
    post: {
      TRACE_TRANSACTION_CLAUSE: () => `/debug/tracers`,
      TRACE_CONTRACT_CALL: () => `/debug/tracers/call`,
      RETRIEVE_STORAGE_RANGE: () => `/debug/storage-range`
    }
  }
};

// src/utils/subscriptions/beat.ts
var getLegacyBeatSubscriptionUrl = (baseURL, options) => {
  return thorest.subscriptions.get.BEAT_LEGACY(baseURL, options?.blockID);
};
var getBeatSubscriptionUrl = (baseURL, options) => {
  return thorest.subscriptions.get.BEAT(baseURL, options?.blockID);
};

// src/utils/subscriptions/block.ts
var getBlockSubscriptionUrl = (baseURL, options) => {
  return thorest.subscriptions.get.BLOCK(baseURL, options?.blockID);
};

// src/utils/subscriptions/event.ts
var import_sdk_core = require("@vechain/sdk-core");
var getEventSubscriptionUrl = (baseURL, event, indexedValues, options) => {
  if (import_sdk_core.vechain_sdk_core_ethers.EventFragment.isFragment(event)) {
    event = event.format("full");
  }
  const ev = new import_sdk_core.abi.Event(event);
  const encodedTopics = ev.encodeFilterTopics(indexedValues ?? []);
  return thorest.subscriptions.get.EVENT(baseURL, {
    position: options?.blockID,
    contractAddress: options?.address,
    topic0: encodedTopics[0],
    topic1: encodedTopics[1],
    topic2: encodedTopics[2],
    topic3: encodedTopics[3],
    topic4: encodedTopics[4]
  });
};

// src/utils/subscriptions/transaction.ts
var getNewTransactionsSubscriptionUrl = (baseURL) => {
  return thorest.subscriptions.get.NEW_TRANSACTIONS(baseURL);
};

// src/utils/subscriptions/transfer.ts
var getVETtransfersSubscriptionUrl = (baseURL, options) => {
  return thorest.subscriptions.get.VET_TRANSFER(baseURL, {
    position: options?.blockID,
    signerAddress: options?.signerAddress,
    sender: options?.sender,
    receiver: options?.recipient
  });
};

// src/utils/subscriptions/index.ts
var subscriptions = {
  getEventSubscriptionUrl,
  getBlockSubscriptionUrl,
  getNewTransactionsSubscriptionUrl,
  getVETtransfersSubscriptionUrl,
  getLegacyBeatSubscriptionUrl,
  getBeatSubscriptionUrl
};

// src/thor-client/accounts/accounts-module.ts
var import_sdk_core2 = require("@vechain/sdk-core");
var AccountsModule = class {
  /**
   * Initializes a new instance of the `Thor` class.
   * @param thor - The Thor instance used to interact with the vechain blockchain API.
   */
  constructor(thor) {
    this.thor = thor;
  }
  /**
   * Retrieves account details such as balance of VET, VTHO, and if the address is a smart contract.
   *
   * @param address - The account address to query details for.
   * @param options - (Optional) Other optional parameters for the request.
   * @returns A promise that resolves to an object containing the account details (balance, energy, hasCode).
   *
   * @throws {InvalidDataTypeError} - Will throw an error if the revision is not a valid block number or ID
   *         or if the address is not a valid address.
   */
  async getAccount(address, options) {
    (0, import_sdk_core2.assertIsAddress)("getAccount", address);
    (0, import_sdk_core2.assertIsRevisionForAccount)("getAccount", options?.revision);
    return await this.thor.httpClient.http(
      "GET",
      thorest.accounts.get.ACCOUNT_DETAIL(address),
      {
        query: buildQuery({ revision: options?.revision })
      }
    );
  }
  /**
   * Fetches the bytecode of a contract at a given address.
   *
   * @param address - The contract address to get the bytecode for.
   * @param options - (Optional) Other optional parameters for the request.
   * @returns A promise that resolves to the contract bytecode as a string.
   *
   * @throws {InvalidDataTypeError} - Will throw an error if the revision is not a valid block number or ID
   *         or if the address is not a valid address.
   */
  async getBytecode(address, options) {
    (0, import_sdk_core2.assertIsAddress)("getBytecode", address);
    (0, import_sdk_core2.assertIsRevisionForAccount)("getBytecode", options?.revision);
    const result = await this.thor.httpClient.http(
      "GET",
      thorest.accounts.get.ACCOUNT_BYTECODE(address),
      {
        query: buildQuery({ revision: options?.revision })
      }
    );
    return result.code;
  }
  /**
   * Retrieves the value from a smart contract's storage at a given position.
   *
   * @param address - The contract address to query storage from.
   * @param position - The position in the storage to retrieve the value from. Must be a 32 bytes hex string (66 characters including `0x` prefix).
   * @param options - (Optional) Other optional parameters for the request.
   * @returns A promise that resolves to the storage value in hex string format.
   *
   * @throws {InvalidDataTypeError} - Will throw an error if the revision is not a valid block number or ID
   *         or if the position is not a 32 bytes hex string or if the address is not a valid address.
   */
  async getStorageAt(address, position, options) {
    (0, import_sdk_core2.assertIsAddress)("getStorageAt", address);
    (0, import_sdk_core2.assertIsRevisionForAccount)("getStorageAt", options?.revision);
    (0, import_sdk_errors7.assert)(
      "getStorageAt",
      import_sdk_core2.Hex0x.isValid(position) && position.length === 66,
      import_sdk_errors7.DATA.INVALID_DATA_TYPE,
      "Invalid `position`. The position must be a hex string of 32 bytes (66 characters including `0x` prefix).",
      { position }
    );
    const result = await this.thor.httpClient.http(
      "GET",
      thorest.accounts.get.STORAGE_AT(address, position),
      {
        query: buildQuery({ position, revision: options?.revision })
      }
    );
    return result.value;
  }
};

// src/thor-client/nodes/nodes-module.ts
var import_sdk_errors8 = require("@vechain/sdk-errors");
var NodesModule = class {
  /**
   * Initializes a new instance of the `Thor` class.
   * @param thor - The Thor instance used to interact with the vechain blockchain API.
   */
  constructor(thor) {
    this.thor = thor;
  }
  /**
   * Retrieves connected peers of a node.
   *
   * @returns A promise that resolves to the list of connected peers.
   */
  async getNodes() {
    return await this.thor.httpClient.http(
      "GET",
      thorest.nodes.get.NODES()
    );
  }
  /**
   * Checks the health of a node using the following algorithm:
   * 1. Make an HTTP GET request to retrieve the last block timestamp.
   * 2. Calculates the difference between the current time and the last block timestamp.
   * 3. If the difference is less than the tolerance, the node is healthy.
   * Note, we could also check '/node/network/peers since' but the difficulty with this approach is
   * if you consider a scenario where the node is connected to 20+ peers, which is healthy, and it receives the new blocks as expected.
   * But what if the node's disk is full, and it's not writing the new blocks to its database? In this case the node is off-sync even
   * though it's technically alive and connected
   * @returns A boolean indicating whether the node is healthy.
   * @throws {InvalidDataTypeError} - if the timestamp key does not exist in the response from the API call to the node
   * @throws {InvalidDataTypeError} - if the timestamp key exists in the response from the API call to the node but the value is not a number
   * @throws {InvalidDataTypeError} - if the response from the API call to the node is not an object
   * @throws {InvalidDataTypeError} - if the response from the API call to the node is null or undefined
   */
  async isHealthy() {
    const response = await this.thor.blocks.getBestBlockCompressed();
    const lastBlockTimestamp = this.getTimestampFromBlock(response);
    const secondsSinceLastBlock = Math.floor(Date.now() / 1e3) - lastBlockTimestamp;
    return Math.abs(secondsSinceLastBlock) < NODE_HEALTHCHECK_TOLERANCE_IN_SECONDS;
  }
  /**
   * Extracts the timestamp from the block
   * @remarks
   * This function throws an error if the timestamp key does not exist in the response from the API call to the node
   * @param response the response from the API call to the node
   * @returns the timestamp from the block
   * @throws {InvalidDataTypeError} - if the timestamp key does not exist in the response from the API call to the node
   * @throws {InvalidDataTypeError} - if the timestamp key exists in the response from the API call to the node but the value is not a number
   * @throws {InvalidDataTypeError} - if the response from the API call to the node is not an object
   * @throws {InvalidDataTypeError} - if the response from the API call to the node is null or undefined
   */
  getTimestampFromBlock = (response) => {
    (0, import_sdk_errors8.assert)(
      "getTimestampFromBlock",
      response !== null && response !== void 0 && typeof response === "object" && "timestamp" in response && typeof response.timestamp === "number",
      import_sdk_errors8.DATA.INVALID_DATA_TYPE,
      "Invalid block format returned from node. The block must be an object with a timestamp key present of type number",
      { response }
    );
    return response?.timestamp;
  };
};

// src/thor-client/transactions/transactions-module.ts
var import_sdk_core5 = require("@vechain/sdk-core");
var import_sdk_errors11 = require("@vechain/sdk-errors");

// src/thor-client/transactions/helpers/delegation-handler.ts
var import_sdk_core3 = require("@vechain/sdk-core");
var import_sdk_errors9 = require("@vechain/sdk-errors");
var _getDelegationSignature = async (tx, delegatorUrl, originAddress, httpClient) => {
  const rawTx = import_sdk_core3.Hex0x.of(tx.encoded);
  const sponsorRequestBody = {
    origin: originAddress,
    raw: rawTx
  };
  try {
    const response = await httpClient.http("POST", delegatorUrl, {
      body: sponsorRequestBody
    });
    return Buffer.from(response.signature.slice(2), "hex");
  } catch (error) {
    throw (0, import_sdk_errors9.buildError)(
      "_getDelegationSignature",
      import_sdk_errors9.TRANSACTION.INVALID_DELEGATION,
      "Delegation failed: Cannot get signature from delegator.",
      { delegatorUrl },
      error
    );
  }
};
var DelegationHandler = (delegator) => {
  const delegatorIsUndefined = delegator === void 0 || delegator === null;
  const isDelegatedWithUrl = !delegatorIsUndefined && delegator?.delegatorUrl !== void 0;
  const isDelegatedWithPrivateKey = !delegatorIsUndefined && delegator?.delegatorPrivateKey !== void 0;
  return {
    /**
     * Check if the transaction is delegated.
     *
     * @returns true if the transaction is delegated, false otherwise.
     */
    isDelegated: () => isDelegatedWithUrl || isDelegatedWithPrivateKey,
    /**
     * Get the delegator options or undefined.
     * (if delegator is undefined or null).
     *
     * @returns The delegator options or undefined.
     */
    delegatorOrUndefined: () => delegatorIsUndefined ? void 0 : delegator,
    /**
     * Get the delegator options or null.
     * (if delegator is undefined or null).
     *
     * @returns The delegator options or null.
     */
    delegatorOrNull: () => delegatorIsUndefined ? null : delegator,
    /**
     * Retrieves the signature of a delegation transaction from a delegator given the endpoint
     * from which to retrieve the signature.
     *
     * @param tx - The transaction to delegate.
     * @param originAddress - The address of the origin account.
     * @param httpClient - The HTTP client instance used for making HTTP requests.
     *
     * @returns A promise that resolves to the signature of the delegation transaction.
     *
     * @see [Simple Gas Payer Standard](https://github.com/vechain/VIPs/blob/master/vips/VIP-201.md)
     *
     * @throws an error if the delegation fails.
     */
    getDelegationSignatureUsingUrl: async (tx, originAddress, httpClient) => {
      (0, import_sdk_errors9.assert)(
        "getDelegationSignatureUsingUrl",
        isDelegatedWithUrl,
        import_sdk_errors9.TRANSACTION.INVALID_DELEGATION,
        "Delegation with url failed: delegatorUrl is not defined.",
        { delegator, tx, originAddress }
      );
      return await _getDelegationSignature(
        tx,
        delegator?.delegatorUrl,
        originAddress,
        httpClient
      );
    }
  };
};

// src/assertions/transactions/assertions.ts
var import_sdk_core4 = require("@vechain/sdk-core");
var import_sdk_errors10 = require("@vechain/sdk-errors");
var assertTransactionCanBeSigned = (methodName, originSignature, txBody) => {
  (0, import_sdk_core4.assertIsValidTransactionSigningPrivateKey)(
    `assertTransactionCanBeSigned - ${methodName}`,
    originSignature,
    import_sdk_core4.secp256k1.isValidPrivateKey,
    "origin"
  );
  (0, import_sdk_errors10.assert)(
    `assertTransactionCanBeSigned - ${methodName}`,
    import_sdk_core4.Transaction.isValidBody(txBody),
    import_sdk_errors10.TRANSACTION.INVALID_TRANSACTION_BODY,
    "Invalid transaction body provided, the transaction cannot be signed. Please check the transaction fields."
  );
};

// src/thor-client/transactions/transactions-module.ts
var TransactionsModule = class {
  /**
   * Initializes a new instance of the `Thor` class.
   * @param thor - The Thor instance used to interact with the vechain blockchain API.
   */
  constructor(thor) {
    this.thor = thor;
  }
  /**
   * Retrieves the details of a transaction.
   *
   * @param id - Transaction ID of the transaction to retrieve.
   * @param options - (Optional) Other optional parameters for the request.
   * @returns A promise that resolves to the details of the transaction.
   */
  async getTransaction(id, options) {
    (0, import_sdk_core5.assertValidTransactionID)("getTransaction", id);
    (0, import_sdk_core5.assertValidTransactionHead)("getTransaction", options?.head);
    return await this.thor.httpClient.http(
      "GET",
      thorest.transactions.get.TRANSACTION(id),
      {
        query: buildQuery({
          raw: options?.raw,
          head: options?.head,
          options: options?.pending
        })
      }
    );
  }
  /**
   * Retrieves the receipt of a transaction.
   *
   * @param id - Transaction ID of the transaction to retrieve.
   * @param options - (Optional) Other optional parameters for the request.
   *                  If `head` is not specified, the receipt of the transaction at the best block is returned.
   * @returns A promise that resolves to the receipt of the transaction.
   */
  async getTransactionReceipt(id, options) {
    (0, import_sdk_core5.assertValidTransactionID)("getTransactionReceipt", id);
    (0, import_sdk_core5.assertValidTransactionHead)("getTransactionReceipt", options?.head);
    return await this.thor.httpClient.http(
      "GET",
      thorest.transactions.get.TRANSACTION_RECEIPT(id),
      {
        query: buildQuery({ head: options?.head })
      }
    );
  }
  /**
   * Retrieves the receipt of a transaction.
   *
   * @param raw - The raw transaction.
   * @returns The transaction id of send transaction.
   */
  async sendRawTransaction(raw) {
    (0, import_sdk_errors11.assert)(
      "sendRawTransaction",
      import_sdk_core5.Hex0x.isValid(raw),
      import_sdk_errors11.DATA.INVALID_DATA_TYPE,
      "Sending failed: Input must be a valid raw transaction in hex format.",
      { raw }
    );
    try {
      import_sdk_core5.TransactionHandler.decode(Buffer.from(raw.slice(2), "hex"), true);
    } catch (error) {
      throw (0, import_sdk_errors11.buildError)(
        "sendRawTransaction",
        import_sdk_errors11.DATA.INVALID_DATA_TYPE,
        "Sending failed: Input must be a valid raw transaction in hex format. Decoding error encountered.",
        { raw },
        error
      );
    }
    return await this.thor.httpClient.http(
      "POST",
      thorest.transactions.post.TRANSACTION(),
      {
        body: { raw }
      }
    );
  }
  /**
   * Sends a signed transaction to the network.
   *
   * @param signedTx - the transaction to send. It must be signed.
   *
   * @returns A promise that resolves to the transaction ID of the sent transaction.
   *
   * @throws an error if the transaction is not signed or if the transaction object is invalid.
   */
  async sendTransaction(signedTx) {
    (0, import_sdk_core5.assertIsSignedTransaction)("sendTransaction", signedTx);
    const rawTx = import_sdk_core5.Hex0x.of(signedTx.encoded);
    return await this.sendRawTransaction(rawTx);
  }
  /**
   * Waits for a transaction to be included in a block.
   *
   * @param txID - The transaction ID of the transaction to wait for.
   * @param options - Optional parameters for the request. Includes the timeout and interval between requests.
   *                  Both parameters are in milliseconds. If the timeout is not specified, the request will not time out!
   *
   * @returns A promise that resolves to the transaction receipt of the transaction. If the transaction is not included in a block before the timeout,
   *          the promise will resolve to `null`.
   *
   * @throws an error if the transaction ID is invalid.
   */
  async waitForTransaction(txID, options) {
    (0, import_sdk_core5.assertValidTransactionID)("waitForTransaction", txID);
    return await Poll.SyncPoll(
      async () => await this.thor.transactions.getTransactionReceipt(txID),
      {
        requestIntervalInMilliseconds: options?.intervalMs,
        maximumWaitingTimeInMilliseconds: options?.timeoutMs
      }
    ).waitUntil((result) => {
      return result !== null;
    });
  }
  /**
   * Builds a transaction body with the given clauses without having to
   * specify the chainTag, expiration, gasPriceCoef, gas, dependsOn and reserved fields.
   *
   * @param clauses - The clauses of the transaction.
   * @param gas - The gas to be used to perform the transaction.
   * @param options - Optional parameters for the request. Includes the expiration, gasPriceCoef, dependsOn and isDelegated fields.
   *                  If the `expiration` is not specified, the transaction will expire after 32 blocks.
   *                  If the `gasPriceCoef` is not specified, the transaction will use the default gas price coef of 127.
   *                  If the `dependsOn is` not specified, the transaction will not depend on any other transaction.
   *                  If the `isDelegated` is not specified, the transaction will not be delegated.
   *
   * @returns A promise that resolves to the transaction body.
   *
   * @throws an error if the genesis block or the latest block cannot be retrieved.
   */
  async buildTransactionBody(clauses, gas, options) {
    const genesisBlock = await this.thor.blocks.getBlockCompressed(0);
    if (genesisBlock === null)
      throw (0, import_sdk_errors11.buildError)(
        "buildTransactionBody",
        import_sdk_errors11.TRANSACTION.INVALID_TRANSACTION_BODY,
        "Error while building transaction body: Cannot get genesis block.",
        { clauses, options }
      );
    const constTxBody = {
      nonce: import_sdk_core5.Hex0x.of(import_sdk_core5.secp256k1.randomBytes(8)),
      expiration: options?.expiration ?? 32,
      clauses,
      gasPriceCoef: options?.gasPriceCoef ?? 0,
      gas,
      dependsOn: options?.dependsOn ?? null,
      reserved: options?.isDelegated === true ? { features: 1 } : void 0
    };
    const latestBlockRef = await this.thor.blocks.getBestBlockRef();
    if (latestBlockRef === null)
      throw (0, import_sdk_errors11.buildError)(
        "buildTransactionBody",
        import_sdk_errors11.TRANSACTION.INVALID_TRANSACTION_BODY,
        "Error while building transaction body: Cannot get latest block.",
        { clauses, options }
      );
    return {
      ...constTxBody,
      chainTag: Number(`0x${genesisBlock.id.slice(64)}`),
      // Last byte of the genesis block ID which is used to identify a network (chainTag)
      blockRef: latestBlockRef
    };
  }
  /**
   * Simulates the execution of a transaction.
   * Allows to estimate the gas cost of a transaction without sending it, as well as to retrieve the return value(s) of the transaction.
   *
   * @param clauses - The clauses of the transaction to simulate.
   * @param options - (Optional) The options for simulating the transaction.
   *
   * @returns A promise that resolves to an array of simulation results.
   *          Each element of the array represents the result of simulating a clause.
   */
  async simulateTransaction(clauses, options) {
    const {
      revision,
      caller,
      gasPrice,
      gasPayer,
      gas,
      blockRef,
      expiration,
      provedWork
    } = options ?? {};
    (0, import_sdk_errors11.assert)(
      "simulateTransaction",
      revision === void 0 || revision === null || import_sdk_core5.revisionUtils.isRevisionAccount(revision),
      import_sdk_errors11.DATA.INVALID_DATA_TYPE,
      "Invalid revision given as input. Input must be a valid revision (i.e., a block number or block ID).",
      { revision }
    );
    return await this.thor.httpClient.http(
      "POST",
      thorest.accounts.post.SIMULATE_TRANSACTION(revision),
      {
        query: buildQuery({ revision }),
        body: {
          clauses: clauses.map((clause) => {
            return {
              ...clause,
              value: BigInt(clause.value).toString()
            };
          }),
          gas,
          gasPrice,
          caller,
          provedWork,
          gasPayer,
          expiration,
          blockRef
        }
      }
    );
  }
  /**
   * Signs a transaction with the given private key and handles the delegation if the transaction is delegated.
   * If the transaction is delegated, the signature of the delegator is retrieved from the delegator endpoint or from the delegator private key.
   *
   * @see [Simple Gas Payer Standard](https://docs.vechain.org/core-concepts/transactions/meta-transaction-features/fee-delegation/designated-gas-payer-vip-191) - Designated Gas Payer (VIP-191)
   *
   * @param txBody - The transaction body to sign.
   * @param privateKey - The private key of the origin account.
   * @param delegatorOptions - Optional parameters for the request. Includes the `delegatorUrl` and `delegatorPrivateKey` fields.
   *                  Only one of the following options can be specified: `delegatorUrl`, `delegatorPrivateKey`.
   *
   * @returns A promise that resolves to the signed transaction.
   */
  async signTransaction(txBody, privateKey, delegatorOptions) {
    const originPrivateKey = Buffer.from(privateKey, "hex");
    assertTransactionCanBeSigned(
      "signTransaction",
      originPrivateKey,
      txBody
    );
    return DelegationHandler(delegatorOptions).isDelegated() ? await this._signWithDelegator(
      txBody,
      originPrivateKey,
      delegatorOptions?.delegatorPrivateKey,
      delegatorOptions?.delegatorUrl
    ) : import_sdk_core5.TransactionHandler.sign(txBody, originPrivateKey);
  }
  /**
   * Signs a transaction where the gas fee is paid by a delegator.
   *
   * @param unsignedTransactionBody - The unsigned transaction body to sign.
   * @param originPrivateKey - The private key of the origin account.
   * @param delegatorPrivateKey - (Optional) The private key of the delegator account.
   * @param delegatorUrl - (Optional) The URL of the endpoint of the delegator.
   *
   * @returns A promise that resolves to the signed transaction.
   *
   * @throws an error if the delegation fails.
   */
  async _signWithDelegator(unsignedTransactionBody, originPrivateKey, delegatorPrivateKey, delegatorUrl) {
    (0, import_sdk_errors11.assert)(
      "_signWithDelegator",
      !(delegatorUrl !== void 0 && delegatorPrivateKey !== void 0),
      import_sdk_errors11.TRANSACTION.INVALID_DELEGATION,
      "Only one of the following options can be specified: delegatorUrl, delegatorPrivateKey"
    );
    const originAddress = import_sdk_core5.addressUtils.fromPublicKey(
      import_sdk_core5.secp256k1.derivePublicKey(originPrivateKey)
    );
    const unsignedTx = new import_sdk_core5.Transaction(unsignedTransactionBody);
    if (delegatorPrivateKey !== void 0)
      return import_sdk_core5.TransactionHandler.signWithDelegator(
        unsignedTransactionBody,
        originPrivateKey,
        Buffer.from(delegatorPrivateKey, "hex")
      );
    const delegatorSignature = await DelegationHandler({
      delegatorUrl
    }).getDelegationSignatureUsingUrl(
      unsignedTx,
      originAddress,
      this.thor.httpClient
    );
    const originSignature = import_sdk_core5.secp256k1.sign(
      unsignedTx.getSignatureHash(),
      originPrivateKey
    );
    const signature = Buffer.concat([originSignature, delegatorSignature]);
    return new import_sdk_core5.Transaction(unsignedTx.body, signature);
  }
};

// src/thor-client/logs/logs-module.ts
var LogsModule = class {
  /**
   * Initializes a new instance of the `Thor` class.
   * @param thor - The Thor instance used to interact with the vechain blockchain API.
   */
  constructor(thor) {
    this.thor = thor;
  }
  /**
   * Filters event logs based on the provided criteria.
   *
   * @param filterOptions - An object specifying filtering criteria for event logs.
   * @returns A promise that resolves to filtered event logs.
   */
  async filterEventLogs(filterOptions) {
    return await this.thor.httpClient.http(
      "POST",
      thorest.logs.post.EVENT_LOGS(),
      {
        query: {},
        body: filterOptions,
        headers: {}
      }
    );
  }
  /**
   * Filters transfer logs based on the provided criteria.
   *
   * @param filterOptions - An object specifying filtering criteria for transfer logs.
   * @returns A promise that resolves to filtered transfer logs.
   */
  async filterTransferLogs(filterOptions) {
    return await this.thor.httpClient.http(
      "POST",
      thorest.logs.post.TRANSFER_LOGS(),
      {
        query: {},
        body: filterOptions,
        headers: {}
      }
    );
  }
};

// src/thor-client/blocks/blocks-module.ts
var import_sdk_errors12 = require("@vechain/sdk-errors");
var import_sdk_core6 = require("@vechain/sdk-core");
var BlocksModule = class {
  /**
   * Initializes a new instance of the `Thor` class.
   * @param thor - The Thor instance used to interact with the vechain blockchain API.
   * @param options - (Optional) Other optional parameters for polling and error handling.
   */
  constructor(thor, options) {
    this.thor = thor;
    this.onBlockError = options?.onBlockError;
    if (options?.isPollingEnabled === true)
      this.setupPolling();
  }
  /**
   * The head block (best block). This is updated by the event poll instance every time a new block is produced.
   * @private
   */
  headBlock = null;
  /**
   * Error handler for block-related errors.
   */
  onBlockError;
  /**
   * The Poll instance for event polling
   * @private
   */
  pollInstance;
  /**
   * Destroys the instance by stopping the event poll.
   */
  destroy() {
    if (this.pollInstance != null) {
      this.pollInstance.stopListen();
    }
  }
  /**
   * Sets up the event polling for the best block.
   * @private
   * */
  setupPolling() {
    this.pollInstance = Poll.createEventPoll(
      async () => await this.thor.blocks.getBestBlockCompressed(),
      1e4
      // Poll every 10 seconds,
    ).onData((data) => {
      this.headBlock = data;
    }).onError(this.onBlockError ?? (() => {
    }));
    this.pollInstance.startListen();
  }
  /**
   * Retrieves details of a compressed specific block identified by its revision (block number or ID).
   *
   * @param revision - The block number or ID to query details for.
   * @returns A promise that resolves to an object containing the details of the compressed block.
   */
  async getBlockCompressed(revision) {
    (0, import_sdk_core6.assertIsRevisionForBlock)("getBlockCompressed", revision);
    return await this.thor.httpClient.http(
      "GET",
      thorest.blocks.get.BLOCK_DETAIL(revision)
    );
  }
  /**
   * Retrieves details of an expanded specific block identified by its revision (block number or ID).
   *
   * @param revision - The block number or ID to query details for.
   * @returns A promise that resolves to an object containing the details of the expanded block.
   */
  async getBlockExpanded(revision) {
    (0, import_sdk_core6.assertIsRevisionForBlock)("getBlockExpanded", revision);
    return await this.thor.httpClient.http(
      "GET",
      thorest.blocks.get.BLOCK_DETAIL(revision),
      {
        query: buildQuery({ expanded: true })
      }
    );
  }
  /**
   * Retrieves details of the latest block.
   *
   * @returns A promise that resolves to an object containing the compressed block details.
   */
  async getBestBlockCompressed() {
    return await this.getBlockCompressed("best");
  }
  /**
   * Retrieves details of the latest block.
   *
   * @returns A promise that resolves to an object containing the expanded block details.
   */
  async getBestBlockExpanded() {
    return await this.getBlockExpanded("best");
  }
  /**
   * Asynchronously retrieves a reference to the best block in the blockchain.
   *
   * This method first calls `getBestBlockCompressed()` to obtain the current best block. If no block is found (i.e., if `getBestBlockCompressed()` returns `null`),
   * the method returns `null` indicating that there's no block to reference. Otherwise, it extracts and returns the first 18 characters of the
   * block's ID, providing the ref to the best block.
   *
   * @returns {Promise<string | null>} A promise that resolves to either a string representing the first 18 characters of the best block's ID,
   * or `null` if no best block is found.
   *
   * @Example:
   * const blockRef = await getBestBlockRef();
   * if (blockRef) {
   *     console.log(`Reference to the best block: ${blockRef}`);
   * } else {
   *     console.log("No best block found.");
   * }
   */
  async getBestBlockRef() {
    const bestBlock = await this.getBestBlockCompressed();
    if (bestBlock === null)
      return null;
    return bestBlock.id.slice(0, 18);
  }
  /**
   * Retrieves the finalized block.
   *
   * @returns A promise that resolves to an object containing the finalized block.
   */
  async getFinalBlockCompressed() {
    return await this.getBlockCompressed("finalized");
  }
  /**
   * Retrieves details of the finalized block.
   *
   * @returns A promise that resolves to an object containing the finalized block details.
   */
  async getFinalBlockExpanded() {
    return await this.getBlockExpanded("finalized");
  }
  /**
   * Synchronously waits for a specific block revision using polling.
   *
   * @param blockNumber - The block number to wait for.
   * @param expanded - A boolean indicating whether to wait for an expanded block.
   * @param options - (Optional) Allows to specify timeout and interval in milliseconds
   * @returns A promise that resolves to an object containing the compressed block.
   */
  async _waitForBlock(blockNumber, expanded, options) {
    (0, import_sdk_errors12.assert)(
      "waitForBlock",
      blockNumber === void 0 || blockNumber === null || blockNumber >= 0,
      import_sdk_errors12.DATA.INVALID_DATA_TYPE,
      "Invalid blockNumber. The blockNumber must be a number representing a block number.",
      { blockNumber }
    );
    return await Poll.SyncPoll(
      async () => expanded ? await this.getBestBlockCompressed() : await this.getBestBlockExpanded(),
      {
        requestIntervalInMilliseconds: options?.intervalMs,
        maximumWaitingTimeInMilliseconds: options?.timeoutMs
      }
    ).waitUntil((result) => {
      return result != null && result?.number >= blockNumber;
    });
  }
  /**
   * Synchronously waits for a specific block revision using polling.
   *
   * @param blockNumber - The block number to wait for.
   * @param options - (Optional) Allows to specify timeout and interval in milliseconds
   * @returns A promise that resolves to an object containing the compressed block.
   */
  async waitForBlockCompressed(blockNumber, options) {
    return await this._waitForBlock(
      blockNumber,
      false,
      options
    );
  }
  /**
   * Synchronously waits for a specific expanded block revision using polling.
   *
   * @param blockNumber - The block number to wait for.
   * @param options - (Optional) Allows to specify timeout and interval in milliseconds
   * @returns A promise that resolves to an object containing the expanded block details.
   */
  async waitForBlockExpanded(blockNumber, options) {
    return await this._waitForBlock(
      blockNumber,
      true,
      options
    );
  }
  /**
   * Returns the head block (best block).
   * @returns {BlockDetail | null} The head block (best block).
   */
  getHeadBlock() {
    return this.headBlock;
  }
  /**
   * Retrieves details of the genesis block.
   *
   * @returns A promise that resolves to an object containing the block details of the genesis block.
   */
  async getGenesisBlock() {
    return await this.getBlockCompressed(0);
  }
};

// src/thor-client/contracts/contracts-module.ts
var import_sdk_core10 = require("@vechain/sdk-core");

// src/thor-client/contracts/model/contract-factory.ts
var import_sdk_core9 = require("@vechain/sdk-core");

// src/thor-client/contracts/model/contract.ts
var import_sdk_core8 = require("@vechain/sdk-core");
var import_sdk_errors14 = require("@vechain/sdk-errors");

// src/thor-client/contracts/model/contract-proxy.ts
var import_sdk_errors13 = require("@vechain/sdk-errors");
var import_sdk_core7 = require("@vechain/sdk-core");

// src/thor-client/contracts/model/contract-filter.ts
var ContractFilter = class {
  /**
   * The smart contract instance to apply the filter on.
   */
  contract;
  /**
   * A set of criteria used to filter events.
   */
  criteriaSet;
  /**
   * Constructs an instance of the `ContractFilter` class.
   *
   * @param contract - The smart contract instance to apply the filter on.
   * @param criteriaSet - A set of criteria used to filter events.
   */
  constructor(contract, criteriaSet) {
    this.contract = contract;
    this.criteriaSet = criteriaSet;
  }
  /**
   * Retrieves event logs based on the specified filter criteria, range, pagination options, and order.
   *
   * @param range - The block range to fetch the events from. Defaults to the entire blockchain history if not provided.
   * @param options - Pagination options for fetching the events.
   * @param order - The order in which to display the events. Defaults to ascending ('asc') if not provided.
   * @returns A promise that resolves to an array of event logs matching the filter criteria.
   */
  async get(range, options, order) {
    const filterEventLogsOptions = {
      range: range ?? {
        unit: "block",
        from: 0,
        to: (await this.contract.thor.blocks.getBestBlockCompressed())?.number
      },
      criteriaSet: this.criteriaSet,
      options,
      order: order ?? "asc"
    };
    return await this.contract.thor.logs.filterEventLogs(
      filterEventLogsOptions
    );
  }
};

// src/thor-client/contracts/model/contract-proxy.ts
function getReadProxy(contract) {
  return new Proxy(contract.read, {
    get: (_target, prop) => {
      return async (...args) => {
        return await contract.thor.contracts.executeContractCall(
          contract.address,
          contract.getFunctionFragment(prop),
          args,
          {
            caller: contract.getCallerPrivateKey() !== void 0 ? import_sdk_core7.addressUtils.fromPrivateKey(
              Buffer.from(
                contract.getCallerPrivateKey(),
                "hex"
              )
            ) : void 0,
            ...contract.getContractReadOptions()
          }
        );
      };
    }
  });
}
function getTransactProxy(contract) {
  return new Proxy(contract.transact, {
    get: (_target, prop) => {
      return async (...args) => {
        if (contract.getCallerPrivateKey() === void 0) {
          throw (0, import_sdk_errors13.buildError)(
            "Contract.getTransactProxy",
            import_sdk_errors13.ERROR_CODES.TRANSACTION.MISSING_PRIVATE_KEY,
            "Caller private key is required to transact with the contract.",
            { prop }
          );
        }
        const transactionOptions = contract.getContractTransactOptions();
        const transactionValue = getTransactionValue(args);
        if (transactionValue !== void 0) {
          args = args.filter((arg) => !isTransactionValue(arg));
        }
        return await contract.thor.contracts.executeContractTransaction(
          contract.getCallerPrivateKey(),
          contract.address,
          contract.getFunctionFragment(prop),
          args,
          {
            ...transactionOptions,
            value: transactionOptions.value ?? transactionValue?.value ?? 0
          }
        );
      };
    }
  });
}
function getFilterProxy(contract) {
  return new Proxy(contract.filters, {
    get: (_target, prop) => {
      return (...args) => {
        const eventFragment = new import_sdk_core7.fragment.Event(
          contract.getEventFragment(prop)
        );
        const topics = new Map(
          eventFragment.encodeFilterTopics(args).map((topic, index) => [index, topic])
        );
        const criteriaSet = [
          {
            address: contract.address,
            topic0: topics.get(0),
            // the first topic is always defined since it's the event signature
            topic1: topics.has(1) ? topics.get(1) : void 0,
            topic2: topics.has(2) ? topics.get(2) : void 0,
            topic3: topics.has(3) ? topics.get(3) : void 0,
            topic4: topics.has(4) ? topics.get(4) : void 0
          }
        ];
        return new ContractFilter(contract, criteriaSet);
      };
    }
  });
}
function getTransactionValue(args) {
  return args.find((arg) => isTransactionValue(arg));
}
function isTransactionValue(obj) {
  return obj.value !== void 0;
}

// src/thor-client/contracts/model/contract.ts
var Contract = class {
  thor;
  address;
  abi;
  callerPrivateKey;
  deployTransactionReceipt;
  read = {};
  transact = {};
  filters = {};
  contractCallOptions = {};
  contractTransactionOptions = {};
  /**
   * Initializes a new instance of the `Contract` class.
   * @param address The address of the contract.
   * @param abi The Application Binary Interface (ABI) of the contract, which defines the contract's methods and events.
   * @param thor An instance of ThorClient to interact with the blockchain.
   * @param callerPrivateKey The private key used for signing transactions.
   * @param transactionReceipt (Optional) The transaction receipt of the contract deployment.
   */
  constructor(address, abi4, thor, callerPrivateKey, transactionReceipt) {
    this.abi = abi4;
    this.thor = thor;
    this.address = address;
    this.deployTransactionReceipt = transactionReceipt;
    this.callerPrivateKey = callerPrivateKey;
    this.read = getReadProxy(this);
    this.transact = getTransactProxy(this);
    this.filters = getFilterProxy(this);
  }
  /**
   * Sets the options for contract calls.
   * @param options - The contract call options to set.
   * @returns The updated contract call options.
   */
  setContractReadOptions(options) {
    this.contractCallOptions = options;
    this.read = getReadProxy(this);
    return this.contractCallOptions;
  }
  /**
   * Clears the current contract call options, resetting them to an empty object.
   * @returns The updated contract call options.
   */
  getContractReadOptions() {
    return this.contractCallOptions;
  }
  /**
   * Clears the current contract call options, resetting them to an empty object.
   */
  clearContractReadOptions() {
    this.contractCallOptions = {};
    this.read = getReadProxy(this);
  }
  /**
   * Sets the options for contract transactions.
   * @param options - The contract transaction options to set.
   * @returns The updated contract transaction options.
   */
  setContractTransactOptions(options) {
    this.contractTransactionOptions = options;
    this.transact = getTransactProxy(this);
    return this.contractTransactionOptions;
  }
  getContractTransactOptions() {
    return this.contractTransactionOptions;
  }
  /**
   * Clears the current contract transaction options, resetting them to an empty object.
   */
  clearContractTransactOptions() {
    this.contractTransactionOptions = {};
    this.transact = getTransactProxy(this);
  }
  /**
   * Sets the private key of the caller for signing transactions.
   * @param privateKey
   */
  setCallerPrivateKey(privateKey) {
    this.callerPrivateKey = privateKey;
    this.transact = getTransactProxy(this);
    this.read = getReadProxy(this);
    return this.callerPrivateKey;
  }
  /**
   * Get the private key of the caller for signing transactions.
   * @returns The private key of the caller.
   */
  getCallerPrivateKey() {
    return this.callerPrivateKey;
  }
  /**
   * Retrieves the function fragment for the specified function name.
   * @param prop - The name of the function.
   * @private
   * @throws An error if the specified function name or symbol is not found in the contract's ABI. The error includes
   * the `ERROR_CODES.ABI.INVALID_FUNCTION` code and a message indicating the function is not present in the ABI.
   *
   */
  getFunctionFragment(prop) {
    const functionFragment = import_sdk_core8.coder.createInterface(this.abi).getFunction(prop.toString());
    if (functionFragment == null) {
      throw (0, import_sdk_errors14.buildError)(
        "Contract.getFunctionFragment",
        import_sdk_errors14.ERROR_CODES.ABI.INVALID_FUNCTION,
        `Function '${prop.toString()}' not found in contract ABI.`,
        { prop }
      );
    }
    return functionFragment;
  }
  /**
   * Retrieves the event fragment for the specified event name.
   * @param eventName - The name of the event.
   * @return The event fragment for the specified event name.
   */
  getEventFragment(eventName) {
    const eventFragment = import_sdk_core8.coder.createInterface(this.abi).getEvent(eventName.toString());
    if (eventFragment == null) {
      throw (0, import_sdk_errors14.buildError)(
        "Contract.getFunctionFragment",
        import_sdk_errors14.ERROR_CODES.ABI.INVALID_FUNCTION,
        `Function '${eventName.toString()}' not found in contract ABI.`,
        { eventName }
      );
    }
    return eventFragment;
  }
};

// src/thor-client/contracts/model/contract-factory.ts
var import_sdk_errors15 = require("@vechain/sdk-errors");
var ContractFactory = class {
  /**
   * The ABI (Application Binary Interface) of the contract.
   */
  abi;
  /**
   * The bytecode of the smart contract.
   */
  bytecode;
  /**
   * The private key used for signing transactions.
   */
  privateKey;
  /**
   * An instance of ThorClient to interact with the blockchain.
   */
  thor;
  /**
   * The result of the deployment transaction, undefined until a deployment is started.
   */
  deployTransaction;
  /**
   * Initializes a new instance of the `ContractFactory` class.
   * @param abi The Application Binary Interface (ABI) of the contract, which defines the contract's methods and events.
   * @param bytecode The compiled bytecode of the contract, representing the contract's executable code.
   * @param privateKey The private key used for signing transactions during contract deployment, ensuring the deployer's identity.
   * @param thor An instance of ThorClient to interact with the blockchain.
   */
  constructor(abi4, bytecode, privateKey, thor) {
    this.abi = abi4;
    this.bytecode = bytecode;
    this.privateKey = privateKey;
    this.thor = thor;
  }
  /**
   * Initiates the deployment of a smart contract.
   *
   * This method performs several steps to deploy a smart contract:
   * 1. Builds a transaction clause for deploying the contract.
   * 2. Estimates the gas cost required for the transaction.
   * 3. Constructs the transaction body with the estimated gas cost.
   * 4. Signs the transaction using the provided private key.
   * 5. Sends the signed transaction to the blockchain.
   *
   * @param {DeployParams?} deployParams (Optional) parameters for contract deployment.
   * @param {ContractTransactionOptions?} options (Optional) transaction options, such as gas limit.
   * @returns {Promise<ContractFactory>} A promise that resolves to the instance of `ContractFactory`,
   *          allowing for fluent chaining of further actions or queries.
   * @throws {Error} Throws an error if any step in the deployment process fails.
   */
  async startDeployment(deployParams, options) {
    const deployContractClause = import_sdk_core9.clauseBuilder.deployContract(
      this.bytecode,
      deployParams
    );
    const gasResult = await this.thor.gas.estimateGas(
      [deployContractClause],
      import_sdk_core9.addressUtils.fromPrivateKey(Buffer.from(this.privateKey, "hex"))
    );
    const txBody = await this.thor.transactions.buildTransactionBody(
      [deployContractClause],
      gasResult.totalGas,
      options
    );
    const signedTx = await this.thor.transactions.signTransaction(
      txBody,
      this.privateKey
    );
    this.deployTransaction = await this.thor.transactions.sendTransaction(signedTx);
    return this;
  }
  /**
   * Waits for the completion of a contract deployment transaction.
   *
   * This method checks for the presence of a deployed transaction result and then
   * waits for the transaction to be processed. Upon successful processing, it
   * constructs and returns a new `Contract` instance based on the transaction receipt.
   *
   * @throws An error if the deployed transaction result is not found or if the
   *         contract deployment fails.
   * @returns {Promise<Contract>} A promise that resolves to a `Contract` instance
   *          once the deployment transaction is completed.
   */
  async waitForDeployment() {
    if (this.deployTransaction === void 0) {
      throw (0, import_sdk_errors15.buildError)(
        "ContractFactory.waitForDeployment",
        import_sdk_errors15.ERROR_CODES.CONTRACT.CONTRACT_DEPLOYMENT_FAILED,
        "Cannot find a contract deployment transaction.",
        { deployTransaction: this.deployTransaction }
      );
    }
    const transactionReceipt = await this.thor.transactions.waitForTransaction(
      this.deployTransaction.id
    );
    (0, import_sdk_errors15.assert)(
      "ContractFactory.waitForDeployment",
      transactionReceipt?.outputs[0]?.contractAddress !== null && transactionReceipt?.outputs[0]?.contractAddress !== void 0,
      import_sdk_errors15.ERROR_CODES.CONTRACT.CONTRACT_DEPLOYMENT_FAILED,
      "Contract deployment failed.",
      { deployTransaction: this.deployTransaction }
    );
    return new Contract(
      transactionReceipt?.outputs[0].contractAddress,
      this.abi,
      this.thor,
      this.privateKey,
      transactionReceipt
    );
  }
  /**
   * Returns the deploy transaction result, if available.
   */
  getDeployTransaction() {
    return this.deployTransaction;
  }
};

// src/thor-client/contracts/contracts-module.ts
var ContractsModule = class {
  /**
   * Initializes a new instance of the `Thor` class.
   * @param thor - The Thor instance used to interact with the vechain blockchain API.
   */
  constructor(thor) {
    this.thor = thor;
  }
  /**
   * Creates a new instance of `ContractFactory` configured with the specified ABI, bytecode, and private key.
   * This factory is used to deploy new smart contracts to the blockchain network managed by this instance.
   *
   * @param abi - The Application Binary Interface (ABI) of the contract, which defines the contract's methods and events.
   * @param bytecode - The compiled bytecode of the contract, representing the contract's executable code.
   * @param privateKey - The private key used for signing transactions during contract deployment, ensuring the deployer's identity.
   * @returns An instance of `ContractFactory` configured with the provided ABI, bytecode, and private key, ready for deploying contracts.
   */
  createContractFactory(abi4, bytecode, privateKey) {
    return new ContractFactory(abi4, bytecode, privateKey, this.thor);
  }
  /**
   * Initializes and returns a new Contract instance with the provided parameters.
   *
   * @param address - The blockchain address of the contract to load.
   * @param abi - The Application Binary Interface (ABI) of the contract, which defines the contract's methods and structures.
   * @param callerPrivateKey - Optional. The private key of the caller, used for signing transactions when interacting with the contract.
   * @returns A new instance of the Contract, initialized with the provided address, ABI, and optionally, a caller private key.
   */
  load(address, abi4, callerPrivateKey) {
    return new Contract(address, abi4, this.thor, callerPrivateKey);
  }
  /**
   * Executes a read-only call to a smart contract function, simulating the transaction to obtain the result.
   *
   * @param contractAddress - The address of the smart contract to interact with.
   * @param functionFragment - The function fragment, including the name and types of the function to be called, derived from the contract's ABI.
   * @param functionData - An array of arguments to be passed to the smart contract function, corresponding to the function's parameters.
   * @param contractCallOptions - (Optional) Additional options for the contract call, such as the sender's address, gas limit, and gas price, which can affect the simulation's context.
   * @returns A promise that resolves to the decoded output of the smart contract function call, the format of which depends on the function's return types.
   *
   * The function simulates a transaction using the provided parameters without submitting it to the blockchain, allowing read-only operations to be tested without incurring gas costs or modifying the blockchain state.
   */
  async executeContractCall(contractAddress, functionFragment, functionData, contractCallOptions) {
    const response = await this.thor.transactions.simulateTransaction(
      [
        {
          to: contractAddress,
          value: "0",
          data: new import_sdk_core10.abi.Function(functionFragment).encodeInput(
            functionData
          )
        }
      ],
      contractCallOptions
    );
    return new import_sdk_core10.abi.Function(functionFragment).decodeOutput(
      response[0].data
    );
  }
  /**
   * Executes a transaction to interact with a smart contract function.
   *
   * @param privateKey - The private key for signing the transaction.
   * @param contractAddress - The address of the smart contract.
   * @param functionFragment - The function fragment, including the name and types of the function to be called, derived from the contract's ABI.
   * @param functionData - The input data for the function.
   * @param options - (Optional) An object containing options for the transaction body. Includes all options of the `buildTransactionBody` method
   *                  besides `isDelegated`.
   *                  @see {@link TransactionsModule.buildTransactionBody}
   *
   * @returns A promise resolving to a SendTransactionResult object.
   */
  async executeContractTransaction(privateKey, contractAddress, functionFragment, functionData, options) {
    const clause = import_sdk_core10.clauseBuilder.functionInteraction(
      contractAddress,
      functionFragment,
      functionData,
      options?.value ?? 0
    );
    const gasResult = await this.thor.gas.estimateGas(
      [clause],
      import_sdk_core10.addressUtils.fromPrivateKey(Buffer.from(privateKey, "hex"))
    );
    const txBody = await this.thor.transactions.buildTransactionBody(
      [clause],
      gasResult.totalGas,
      options
    );
    const signedTx = await this.thor.transactions.signTransaction(
      txBody,
      privateKey
    );
    const result = await this.thor.transactions.sendTransaction(signedTx);
    result.wait = async () => await this.thor.transactions.waitForTransaction(result.id);
    return result;
  }
  /**
   * Gets the base gas price in wei.
   * The base gas price is the minimum gas price that can be used for a transaction.
   * It is used to obtain the VTHO (energy) cost of a transaction.
   *
   * @link [Total Gas Price](https://docs.vechain.org/core-concepts/transactions/transaction-calculation#total-gas-price)
   *
   * @returns The base gas price in wei.
   */
  async getBaseGasPrice() {
    return await this.executeContractCall(
      import_sdk_core10.PARAMS_ADDRESS,
      import_sdk_core10.coder.createInterface(import_sdk_core10.PARAMS_ABI).getFunction("get"),
      [import_sdk_core10.dataUtils.encodeBytes32String("base-gas-price")]
    );
  }
};

// src/thor-client/gas/gas-module.ts
var import_sdk_errors16 = require("@vechain/sdk-errors");
var import_sdk_core13 = require("@vechain/sdk-core");

// src/thor-client/gas/helpers/decode-evm-error.ts
var import_sdk_core12 = require("@vechain/sdk-core");

// src/thor-client/gas/helpers/const.ts
var import_sdk_core11 = require("@vechain/sdk-core");
var SOLIDITY_ERROR_SELECTOR = (0, import_sdk_core11.keccak256)("Error(string)", "hex").slice(0, 10);
var SOLIDITY_PANIC_SELECTOR = (0, import_sdk_core11.keccak256)("Panic(uint256)", "hex").slice(0, 10);

// src/thor-client/gas/helpers/decode-evm-error.ts
function decodeRevertReason(data) {
  if (data.startsWith(SOLIDITY_ERROR_SELECTOR))
    return import_sdk_core12.abi.decode(
      "string",
      "0x" + data.slice(SOLIDITY_ERROR_SELECTOR.length)
    );
  if (data.startsWith(SOLIDITY_PANIC_SELECTOR)) {
    const decoded = import_sdk_core12.abi.decode(
      "uint256",
      "0x" + data.slice(SOLIDITY_PANIC_SELECTOR.length)
    );
    return `Panic(0x${parseInt(decoded).toString(16).padStart(2, "0")})`;
  }
}

// src/thor-client/gas/gas-module.ts
var GasModule = class {
  /**
   * Initializes a new instance of the `Thor` class.
   * @param thor - The Thor instance used to interact with the vechain blockchain API.
   */
  constructor(thor) {
    this.thor = thor;
  }
  /**
   * Simulates a transaction and returns an object containing information regarding the gas used and whether the transaction reverted.
   *
   * @param clauses - The clauses of the transaction to simulate.
   * @param caller - The address of the account sending the transaction.
   * @param options - Optional parameters for the request. Includes all options of the `simulateTransaction` method excluding the `caller` option.
   *                  @see {@link TransactionsClient#simulateTransaction}
   *                  Also, includes the `gasPadding` option which is a percentage of gas to add on top of the estimated gas. The value must be between (0, 1].
   *
   * @note The caller option is suggested as estimation without this parameter may not be accurate.
   *
   * @returns An object containing information regarding the gas used and whether the transaction reverted, together with the decoded revert reason and VM errors.
   *
   * @throws an error if the clauses are invalid or if an error occurs during the simulation.
   */
  async estimateGas(clauses, caller, options) {
    (0, import_sdk_errors16.assert)(
      "estimateGas",
      clauses.length > 0,
      import_sdk_errors16.DATA.INVALID_DATA_TYPE,
      "Invalid clauses. Clauses must be an array of clauses with at least one clause."
    );
    (0, import_sdk_errors16.assert)(
      "estimateGas",
      options?.gasPadding === void 0 || options.gasPadding > 0 && options.gasPadding <= 1,
      import_sdk_errors16.DATA.INVALID_DATA_TYPE,
      "Invalid gasPadding. gasPadding must be a number between (0, 1]."
    );
    const simulations = await this.thor.transactions.simulateTransaction(
      clauses,
      {
        caller,
        ...options
      }
    );
    const isReverted = simulations.some((simulation) => {
      return simulation.reverted;
    });
    const intrinsicGas = import_sdk_core13.TransactionUtils.intrinsicGas(clauses);
    const totalSimulatedGas = simulations.reduce((sum, simulation) => {
      return sum + simulation.gasUsed;
    }, 0);
    const totalGas = (intrinsicGas + (totalSimulatedGas !== 0 ? totalSimulatedGas + 15e3 : 0)) * (1 + (options?.gasPadding ?? 0));
    return isReverted ? {
      totalGas,
      reverted: true,
      revertReasons: simulations.map((simulation) => {
        return decodeRevertReason(simulation.data) ?? "";
      }),
      vmErrors: simulations.map((simulation) => {
        return simulation.vmError;
      })
    } : {
      totalGas,
      reverted: false,
      revertReasons: [],
      vmErrors: []
    };
  }
};

// src/thor-client/debug/debug-module.ts
var import_sdk_errors17 = require("@vechain/sdk-errors");
var import_sdk_core14 = require("@vechain/sdk-core");
var DebugModule = class {
  /**
   * Initializes a new instance of the `Thor` class.
   * @param thor - The Thor instance used to interact with the vechain blockchain API.
   */
  constructor(thor) {
    this.thor = thor;
  }
  /**
   * Trace transaction clause.
   *
   * This endpoint allows you to create a tracer for a specific clause.
   * Tracers are instrumental in monitoring and analyzing the execution flow within the EVM.
   * You can customize the tracer using various options to tailor it to your specific debugging needs.
   *
   * @param input - The input for the trace transaction clause. It has:
   * * target - The target of the tracer. It is a combination of blockID, transaction (transaction ID or index into block), and clauseIndex.
   * * config - The configuration of the tracer. It is specific to the name of the tracer.
   * @param name - The name of the tracer to use. It determines Output and Input configuration.
   *
   * @throws{InvalidDataTypeError} - If the input is invalid.
   */
  async traceTransactionClause(input, name) {
    this.validateTarget(input.target, "traceTransactionClause");
    const parsedTarget = `${input.target.blockID}/${input.target.transaction}/${input.target.clauseIndex}`;
    return await this.thor.httpClient.http(
      "POST",
      thorest.debug.post.TRACE_TRANSACTION_CLAUSE(),
      {
        query: {},
        body: {
          target: parsedTarget,
          name,
          config: input.config
        },
        headers: {}
      }
    );
  }
  /**
   * Trace a contract call.
   *
   * This endpoint enables clients to create a tracer for a specific function call.
   * You can customize the tracer using various options to suit your debugging requirements.
   *
   * @param input - The input for the trace contract call. It has:
   * * contractInput - The contract call information.
   * * config - The configuration of the tracer. It is specific to the name of the tracer.
   * * transactionOptions - The transaction options.
   * @param name - The name of the tracer to use. It determines Output and Input configuration.
   *
   * @throws{InvalidDataTypeError} - If the input is invalid.
   */
  async traceContractCall(input, name) {
    if (input.contractInput?.to !== void 0 && input.contractInput.to !== null) {
      (0, import_sdk_errors17.assert)(
        "traceContractCall",
        import_sdk_core14.addressUtils.isAddress(input.contractInput.to),
        import_sdk_errors17.DATA.INVALID_DATA_TYPE,
        `Invalid address '${input.contractInput.to}' given as input for traceContractCall.`,
        { address: input.contractInput.to }
      );
    }
    if (input.contractInput?.data !== void 0)
      (0, import_sdk_errors17.assert)(
        "traceContractCall",
        import_sdk_core14.Hex0x.isValid(input.contractInput.data, true),
        import_sdk_errors17.DATA.INVALID_DATA_TYPE,
        `Invalid data '${input.contractInput?.data}' given as input for traceContractCall.`,
        { data: input.contractInput?.data }
      );
    if (input.contractInput?.value !== void 0)
      (0, import_sdk_errors17.assert)(
        "traceContractCall",
        import_sdk_core14.Hex0x.isValid(input.contractInput.value),
        import_sdk_errors17.DATA.INVALID_DATA_TYPE,
        `Invalid value '${input.contractInput?.value}' given as input for traceContractCall.`,
        { value: input.contractInput?.value }
      );
    return await this.thor.httpClient.http(
      "POST",
      thorest.debug.post.TRACE_CONTRACT_CALL(),
      {
        query: {},
        body: {
          to: input.contractInput?.to,
          data: input.contractInput?.data,
          value: input.contractInput?.value,
          name,
          gas: input.transactionOptions?.gas,
          gasPrice: input.transactionOptions?.gasPrice,
          caller: input.transactionOptions?.caller,
          provedWork: input.transactionOptions?.provedWork,
          gasPayer: input.transactionOptions?.gasPayer,
          expiration: input.transactionOptions?.expiration,
          blockRef: input.transactionOptions?.blockRef,
          config: input.config
        },
        headers: {}
      }
    );
  }
  /**
   * Retrieve the storage range.
   *
   * This endpoint enables clients to retrieve the storage range for the
   * coordinates specified in the `input` parameter.
   *
   * @param input - the coordinates to retrieve the storage range. It has:
   * * target - {@link TransactionTraceTarget} specifies `blockID`,
   *           `transaction` address and `clauseIndex` number.
   * * options - {@link RetrieveStorageRangeInputOptions} specified the
   *           `address` if the contract or account to retrieve the
   *           storage range for. Nullable.
   */
  async retrieveStorageRange(input) {
    this.validateTarget(input.target, "retrieveStorageRange");
    const parsedTarget = `${input.target.blockID}/${input.target.transaction}/${input.target.clauseIndex}`;
    return await this.thor.httpClient.http(
      "POST",
      thorest.debug.post.RETRIEVE_STORAGE_RANGE(),
      {
        query: {},
        body: {
          target: parsedTarget,
          address: input.options?.address,
          keyStart: input.options?.keyStart,
          maxResult: input.options?.maxResult
        },
        headers: {}
      }
    );
  }
  /**
   * Validate target of traceTransactionClause and retrieveStorageRange.
   *
   * @param target - Target of traceTransactionClause and retrieveStorageRange to validate.
   * @param functionName - The name of the function.
   *
   * @private
   *
   * @throws{InvalidDataTypeError} - If the input is invalid.
   */
  validateTarget(target, functionName) {
    (0, import_sdk_errors17.assert)(
      "validateTarget",
      import_sdk_core14.Hex0x.isThorId(target.blockID),
      import_sdk_errors17.DATA.INVALID_DATA_TYPE,
      `Invalid block ID '${target.blockID}' given as input for ${functionName}.`,
      { blockId: target.blockID }
    );
    if (typeof target.transaction === "string")
      (0, import_sdk_errors17.assert)(
        "validateTarget",
        import_sdk_core14.Hex0x.isThorId(target.transaction),
        import_sdk_errors17.DATA.INVALID_DATA_TYPE,
        `Invalid transaction id '${target.transaction}' given as input for ${functionName}.`,
        { transaction: target.transaction }
      );
    else
      (0, import_sdk_errors17.assert)(
        "validateTarget",
        target.transaction >= 0,
        import_sdk_errors17.DATA.INVALID_DATA_TYPE,
        `Invalid transaction index '${target.transaction}' given as input for ${functionName}.`,
        { transaction: target.transaction }
      );
    (0, import_sdk_errors17.assert)(
      "validateTarget",
      target.clauseIndex >= 0,
      import_sdk_errors17.DATA.INVALID_DATA_TYPE,
      `Invalid clause index '${target.clauseIndex}' given as input for ${functionName}.`,
      { clauseIndex: target.clauseIndex }
    );
  }
};

// src/thor-client/thor-client.ts
var ThorClient = class _ThorClient {
  /**
   * Constructs a new `ThorClient` instance with a given HTTP client.
   *
   * @param httpClient - The HTTP client instance used for making network requests.
   * @param options - (Optional) Other optional parameters for polling and error handling.
   */
  constructor(httpClient, options) {
    this.httpClient = httpClient;
    this.accounts = new AccountsModule(this);
    this.nodes = new NodesModule(this);
    this.blocks = new BlocksModule(this, options);
    this.logs = new LogsModule(this);
    this.transactions = new TransactionsModule(this);
    this.contracts = new ContractsModule(this);
    this.gas = new GasModule(this);
    this.debug = new DebugModule(this);
  }
  /**
   * The `AccountsModule` instance
   */
  accounts;
  /**
   * The `NodesModule` instance
   */
  nodes;
  /**
   * The `BlocksModule` instance
   */
  blocks;
  /**
   * The `LogsModule` instance used for interacting with log-related endpoints.
   */
  logs;
  /*
   * The `TransactionsModule` instance
   */
  transactions;
  /**
   * The 'ContractClient' instance
   */
  contracts;
  /**
   * The `GasModule` instance
   */
  gas;
  /**
   * The `DebugModule` instance
   */
  debug;
  /**
   * Creates a new `ThorClient` instance from a given URL.
   *
   * @param networkUrl - The URL of the network to connect to.
   * @param options - (Optional) Other optional parameters for polling and error handling.
   * @returns A new `ThorClient` instance.
   */
  static fromUrl(networkUrl, options) {
    return new _ThorClient(new HttpClient(networkUrl), options);
  }
  /**
   * Destroys the `ThorClient` instance by stopping the event polling
   * and any other cleanup.
   */
  destroy() {
    this.blocks.destroy();
  }
};
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  AccountsModule,
  BlocksModule,
  Contract,
  ContractFactory,
  ContractsModule,
  DEFAULT_HTTP_TIMEOUT,
  DelegationHandler,
  HTTPS_REGEX,
  HTTP_REGEX,
  HttpClient,
  LogsModule,
  NODE_HEALTHCHECK_TOLERANCE_IN_SECONDS,
  NodesModule,
  Poll,
  ThorClient,
  TransactionsModule,
  buildQuery,
  convertError,
  network,
  sanitizeWebsocketBaseURL,
  subscriptions,
  thorest,
  toQueryString
});
